import {
    getEnrichedRoute,
} from '@/router/helpers';
import { SIDEBAR_POSITIONS, BkNextRouteInterface } from '@/router/helpers/constants';
import { SETTINGS_ROUTE_NAMES } from '@/constants/routeNames';
import useIdentifyService from '@/composables/useElementIdentifierService';
import { MODULE_CONTENT_LOCAL, MODULE_TICKETS } from '@/models/Modules';
import { getModuleMessages } from './locales';
import Home from './index.vue';

const moduleName = 'settings';
const { identify } = useIdentifyService({ prefix: 'navBlock.settings' });
const Manticore = () => import('@/components/Manticore/index.vue');

const routes = {
    path: `/${SETTINGS_ROUTE_NAMES.MAIN}`,
    name: SETTINGS_ROUTE_NAMES.MAIN,
    label: 'sidenav.settings.main.label',
    component: Home,
    meta: {
        title: 'sidenav.settings.main.label',
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.BOTTOM,
            icon: 'Settings',
        },
    },
    children: [
        {
            path: SETTINGS_ROUTE_NAMES.MODULES,
            name: SETTINGS_ROUTE_NAMES.MODULES,
            label: 'sidenav.settings.modules.label',
            component: Manticore,
            meta: {
                title: 'sidenav.settings.modules.label',
                manticoreMatches: [/setting\/modules+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'setting/modules',
            },
            'data-cy': identify('modules'),
        },
        {
            path: SETTINGS_ROUTE_NAMES.EMAIL,
            name: SETTINGS_ROUTE_NAMES.EMAIL,
            label: 'sidenav.settings.emailConfiguration.label',
            component: Manticore,
            meta: {
                title: 'sidenav.settings.emailConfiguration.label',
                manticoreMatches: [/setting\/emails+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'setting/emails',
            },
            'data-cy': identify('emailConfiguration'),
        },
        {
            path: SETTINGS_ROUTE_NAMES.TICKET,
            name: SETTINGS_ROUTE_NAMES.TICKET,
            label: 'sidenav.settings.ticketConfiguration.label',
            component: Manticore,
            meta: {
                title: 'sidenav.settings.ticketConfiguration.label',
                manticoreMatches: [/customization\/tickets+(([?/])+.*)?/],
                modulesToBeActive: [MODULE_TICKETS],
            },
            props: {
                iFrameUrl: 'customization/tickets',
            },
            'data-cy': identify('ticketConfiguration'),
        },
        {
            path: SETTINGS_ROUTE_NAMES.VOUCHER,
            name: SETTINGS_ROUTE_NAMES.VOUCHER,
            label: 'sidenav.settings.voucherConfiguration.label',
            component: Manticore,
            meta: {
                title: 'sidenav.settings.voucherConfiguration.label',
                manticoreMatches: [/customization\/vouchers+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'customization/vouchers',
            },
            'data-cy': identify('voucherConfiguration'),
        },
        {
            path: SETTINGS_ROUTE_NAMES.FEED,
            name: SETTINGS_ROUTE_NAMES.FEED,
            label: 'sidenav.settings.calendarFeed.label',
            component: Manticore,
            meta: {
                title: 'sidenav.settings.calendarFeed.label',
                manticoreMatches: [/calendar\/feed+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'calendar/feed',
            },
            'data-cy': identify('calendarFeed'),
        },
        {
            path: SETTINGS_ROUTE_NAMES.INVOICELAYOUT,
            name: SETTINGS_ROUTE_NAMES.INVOICELAYOUT,
            label: 'sidenav.settings.invoiceLayout.label',
            component: Manticore,
            meta: {
                title: 'sidenav.settings.invoiceLayout.label',
                manticoreMatches: [/customization\/invoice+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'customization/invoice',
            },
            'data-cy': identify('invoiceLayout'),
        },
        {
            path: SETTINGS_ROUTE_NAMES.INVOICENUMBER,
            name: SETTINGS_ROUTE_NAMES.INVOICENUMBER,
            label: 'sidenav.settings.invoiceNNumber.label',
            component: Manticore,
            meta: {
                title: 'sidenav.settings.invoiceNNumber.label',
                manticoreMatches: [/customization\/invoiceSchemaList+(([?/])+.*)?/, /customization\/invoiceSchema+.*/],
            },
            props: {
                iFrameUrl: 'customization/invoiceSchemaList',
            },
            'data-cy': identify('invoiceNNumber'),
        },
        {
            path: SETTINGS_ROUTE_NAMES.TRANSLATIONS,
            name: SETTINGS_ROUTE_NAMES.TRANSLATIONS,
            label: 'sidenav.settings.translations.label',
            component: Manticore,
            meta: {
                title: 'sidenav.settings.translations.label',
                manticoreMatches: [/localization\/index+(([?/])+.*)?/, /localization\/serviceNotAvailable+.*/, /localization\/translationsPush+.*/,
                    /localization\/translationsPush+(([?/])+.*)?/, /localization\/setInitialLocale+(([?/])+.*)?/, /localization\/settings+(([?/])+.*)?/],
                modulesToBeActive: [MODULE_CONTENT_LOCAL],
            },
            props: {
                iFrameUrl: 'localization/index',
            },
            'data-cy': identify('translations'),
        },
        {
            path: SETTINGS_ROUTE_NAMES.VAT,
            name: SETTINGS_ROUTE_NAMES.VAT,
            label: 'sidenav.settings.vat.label',
            component: Manticore,
            meta: {
                title: 'sidenav.settings.vat.label',
                manticoreMatches: [/setting\/vat+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'setting/vat',
            },
            'data-cy': identify('vat'),
        },
    ],
} as unknown as BkNextRouteInterface;

export default getEnrichedRoute(routes, moduleName, getModuleMessages);
