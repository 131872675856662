import {
    getEnrichedRoute,
} from '@/router/helpers';
import { SIDEBAR_POSITIONS, BkNextMainModuleRouteInterface } from '@/router/helpers/constants';
import useIdentifyService from '@/composables/useElementIdentifierService';
import { CALENDAR_ROUTE_NAMES, LEGACY_ROUTE_NAME } from '@/constants/routeNames';
import { CALENDAR_MODULE } from '@/constants/modules';
import { RouteLocationNormalized } from 'vue-router';
import { CalendarDayViewType } from '@/modules/calendar/pages/DayView/composables/constants';
import { SCOPE_BOOKING } from '@/models/User';
import { ManticoreComponentProps } from '@/components/Manticore/useManticoreNavigation';
import { getModuleMessages } from './locales';

const Manticore = () => import('@/components/Manticore/index.vue');
const DayView = () => import('./pages/DayView/index.vue');
const { identify } = useIdentifyService({ prefix: 'navBlock.calendar' });

export const routes = {
    path: `/${CALENDAR_ROUTE_NAMES.MAIN}`,
    name: CALENDAR_ROUTE_NAMES.MAIN,
    label: 'sidenav.calendar.main.label',
    hiddenRoutes: [LEGACY_ROUTE_NAME.EVENT_RULE_CREATE, LEGACY_ROUTE_NAME.EVENT_DATE],
    meta: {
        title: 'sidenav.calendar.main.label',
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.TOP,
            icon: 'calendar',
        },
        moduleName: CALENDAR_MODULE,
        loadModuleAssets: getModuleMessages,
    },
    children: [
        {
            path: '',
            name: CALENDAR_ROUTE_NAMES.DAY_BETA,
            label: 'sidenav.calendar.main.label',
            component: DayView,
            meta: {
                title: 'sidenav.calendar.main.label',
                icon: 'calendar',
                badge: {
                    value: 'new',
                    intent: 'primary',
                },
                scopesRequired: [SCOPE_BOOKING],
            },
            props: (route:RouteLocationNormalized): CalendarDayViewType => ({
                date: route.query?.date as string |undefined,
                experiences: route.query?.experiences as string |undefined,
                withBookings: route.query?.withBookings as string |undefined,
                withRequests: route.query?.withRequests as string |undefined,
                products: route.query?.products as string |undefined,
                labels: route.query?.labels as string |undefined,
            }),
            'data-cy': identify('day-beta'),
        },
        {
            path: `${CALENDAR_ROUTE_NAMES.MONTH}`,
            name: CALENDAR_ROUTE_NAMES.MONTH,
            label: 'sidenav.calendar.month.label',
            component: Manticore,
            props: {
                iFrameUrl: 'calendar/index',
            },
            meta: {
                title: 'sidenav.calendar.month.label',
                manticoreMatches: [/calendar\/index+(([?/])+.*)?/],
            },
            'data-cy': identify('month'),
        },
        {
            path: CALENDAR_ROUTE_NAMES.DAY,
            name: CALENDAR_ROUTE_NAMES.DAY,
            label: 'sidenav.calendar.day.label',
            component: Manticore,
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: 'agenda/index',
                query: {
                    date: route.query.date,
                },
            }),
            meta: {
                title: 'sidenav.calendar.day.label',
                manticoreMatches: [/agenda\/index+(([?/])+.*)?/],
            },
            'data-cy': identify('day'),
        },
        {
            path: `${CALENDAR_ROUTE_NAMES.DAY_BETA}`,
            redirect: { name: CALENDAR_ROUTE_NAMES.MAIN },
        },

    ],
} as BkNextMainModuleRouteInterface;

export default getEnrichedRoute(routes, CALENDAR_MODULE, getModuleMessages);
