import {
    getEnrichedRoute,
} from '@/router/helpers';
import { SIDEBAR_POSITIONS, BkNextRouteInterface } from '@/router/helpers/constants';

import useIdentifyService from '@/composables/useElementIdentifierService';
import { LEGACY_ROUTE_NAME, SELL_ROUTE_NAMES } from '@/constants/routeNames';
import { MODULE_EMAIL_CAMPAIGN, MODULE_NEW_WIDGETS } from '@/models/Modules';
import { SELL_MODULE } from '@/constants/modules';
import { BOOKINGKIT_ROLES } from '@/constants/roles';
import { getModuleMessages } from './locales';
import Home from './index.vue';

const { identify } = useIdentifyService({ prefix: 'navBlock.sell' });
const Manticore = () => import('@/components/Manticore/index.vue');
const PaymentMethodsComponent = () => import('./pages/PaymentMethods/index.vue');
const PaymentProvidersComponent = () => import('./pages/PaymentProviders/index.vue');
const Configurator = () => import('./pages/WidgetSettings/index.vue');

const NewWidgetsPage = () => import('./pages/NewWidgets/index.vue');

const routes = {
    path: `/${SELL_ROUTE_NAMES.MAIN}`,
    name: SELL_ROUTE_NAMES.MAIN,
    label: 'sidenav.sell.main.label',
    hiddenRoutes: [LEGACY_ROUTE_NAME.PAYMENT_OPTIONS_MANGOPAY, SELL_ROUTE_NAMES.NEW_WIDGET_CONFIG],
    component: Home,
    meta: {
        title: 'sidenav.sell.main.label',
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.TOP,
            icon: 'target',
        },
    },
    children: [
        {
            path: `${SELL_ROUTE_NAMES.NEW_WIDGETS}`,
            name: SELL_ROUTE_NAMES.NEW_WIDGETS,
            label: 'sidenav.sell.newWidgets.label',
            component: NewWidgetsPage,
            meta: {
                modulesToBeActive: [MODULE_NEW_WIDGETS],
                title: 'sidenav.sell.newWidgets.label',
                badge: {
                    value: 'new',
                },
            },
            'data-cy': identify('newWidgets'),
        },
        {
            path: `${SELL_ROUTE_NAMES.NEW_WIDGETS}/:id`,
            name: SELL_ROUTE_NAMES.NEW_WIDGET_CONFIG,
            label: 'sidenav.sell.newWidgets.label',
            component: Configurator,
            meta: {
                modulesToBeActive: [MODULE_NEW_WIDGETS],
                title: 'sidenav.sell.newWidgets.label',
            },
            'data-cy': identify('widgets'),
        },
        {
            path: SELL_ROUTE_NAMES.WIDGETS,
            name: SELL_ROUTE_NAMES.WIDGETS,
            label: 'sidenav.sell.widgets.label',
            component: Manticore,
            meta: {
                title: 'sidenav.sell.widgets.label',
                manticoreMatches: [/marketing\/website+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'marketing/website',
            },
            'data-cy': identify('widgets'),
        },
        {
            path: SELL_ROUTE_NAMES.CHECKOUT,
            name: SELL_ROUTE_NAMES.CHECKOUT,
            label: 'sidenav.sell.checkoutConfiguration.label',
            component: Manticore,
            meta: {
                title: 'sidenav.sell.checkoutConfiguration.label',
                manticoreMatches: [/marketing\/configuration+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'marketing/configuration',
            },
            'data-cy': identify('checkoutConfiguration'),
        },
        {
            path: SELL_ROUTE_NAMES.EMAILCAMPAIGNS,
            name: SELL_ROUTE_NAMES.EMAILCAMPAIGNS,
            label: 'sidenav.sell.emailCampaigns.label',
            component: Manticore,
            meta: {
                title: 'sidenav.sell.emailCampaigns.label',
                manticoreMatches: [/emailCampaign\/index+(([?/])+.*)?/, /emailCampaign\/form+.*/],
                modulesToBeActive: [MODULE_EMAIL_CAMPAIGN],
            },
            props: {
                iFrameUrl: 'emailCampaign/index',
            },
            'data-cy': identify('emailCampaigns'),
        },
        {
            path: SELL_ROUTE_NAMES.PAYMENTOPTIONS,
            name: SELL_ROUTE_NAMES.PAYMENTOPTIONS,
            label: 'sidenav.sell.paymentOptions.label',
            component: PaymentMethodsComponent,
            meta: {
                title: 'sidenav.sell.paymentOptions.label',
            },
            props: {
                iFrameUrl: 'setting/paymentOptions',
                routePage: SELL_ROUTE_NAMES.PAYMENTOPTIONS,
            },
            'data-cy': identify('paymentOptions'),
        },
        {
            path: LEGACY_ROUTE_NAME.PAYMENT_OPTIONS_MANGOPAY,
            name: LEGACY_ROUTE_NAME.PAYMENT_OPTIONS_MANGOPAY,
            component: Manticore,
            props: {
                iFrameUrl: 'setting/paymentOptions',
                routePage: SELL_ROUTE_NAMES.PAYMENTOPTIONS,
            },
            'data-cy': identify('paymentOptions'),
        },
        {
            path: SELL_ROUTE_NAMES.PAYMENTPROVIDERS,
            name: SELL_ROUTE_NAMES.PAYMENTPROVIDERS,
            label: 'sidenav.sell.paymentProviders.label',
            component: PaymentProvidersComponent,
            meta: {
                title: 'sidenav.sell.paymentProviders.label',
            },
            props: {
                iFrameUrl: 'setting/paymentProviders',
                routePage: SELL_ROUTE_NAMES.PAYMENTPROVIDERS,
            },
            'data-cy': identify('paymentProviders'),
        },
    ],
} as unknown as BkNextRouteInterface;

export default getEnrichedRoute(routes, SELL_MODULE, getModuleMessages);
