import {
    getEnrichedRoute,
} from '@/router/helpers';
import { SIDEBAR_POSITIONS, BkNextMainModuleRouteInterface } from '@/router/helpers/constants';
import { MARKETPLACE_ROUTES_NAMES } from '@/constants/routeNames';
import { APP_MARKETPLACE_MODULE } from '@/constants/modules';
import { getModuleMessages } from './locales';

const ManticoreComponent = () => import('@/components/Manticore/index.vue');

export const MARKETPLACE_ROUTE = {
    path: `/${MARKETPLACE_ROUTES_NAMES.MAIN}`,
    name: MARKETPLACE_ROUTES_NAMES.PARENT,
    label: 'sidenav.marketplace.main.label',
    hiddenRoutes: [MARKETPLACE_ROUTES_NAMES.APP_DETAIL],
    meta: {
        manticoreMatches: [/extension\/marketplace/],
        title: 'sidenav.marketplace.main.label',
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.TOP,
            icon: 'AppStore',
        },
        moduleName: APP_MARKETPLACE_MODULE,
        loadModuleAssets: getModuleMessages,
    },
    children: [

        {
            path: '',
            name: MARKETPLACE_ROUTES_NAMES.MAIN,
            label: 'sidenav.marketplace.main.label',
            meta: {
                manticoreMatches: [/extension\/marketplace/],
                title: 'sidenav.marketplace.main.label',
            },
            component: ManticoreComponent,
            props: {
                iFrameUrl: 'extension/marketplace',
            },
        },
    ],
} as BkNextMainModuleRouteInterface;

export default getEnrichedRoute(MARKETPLACE_ROUTE, APP_MARKETPLACE_MODULE, getModuleMessages);
