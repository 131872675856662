import { BkNextRouteInterface, SIDEBAR_POSITIONS } from '@/router/helpers/constants';
import { ManticoreRoutes } from '@/router/LegacyRoutes';
import { OVERVIEW_ROUTES_NAMES, SHORTCUTS_ROUTES_NAMES } from '@/constants';
import {
    SCOPE_BOOKING_WRITE,
    SCOPE_EVENT,
    SCOPE_EVENT_WRITE,
    SCOPE_ORDER_CREATE_AND_EDIT,
} from '@/models/User';

import AdminRoutes from '@/modules/admin/routes';
import Overview from '@/modules/overview/routes';
import CalendarRoutes from '@/modules/calendar/routes';
import OrdersRoutes from '@/modules/orders/routes';
import InventoryRoutes from '@/modules/inventory/routes';
import ReportsRoutes from '@/modules/reports/routes';
import WalletManagement from '@/modules/wallet/routes';
import MarketingRoutes from '@/modules/sell/routes';
import ReachRoutes from '@/modules/reach/routes';
import SettingsRoutes from '@/modules/settings/routes';
import ProductUpdatesRoutes from '@/modules/productUpdates/routes';
import UserRoutes from '@/modules/user/routes';
import Error from '@/views/Error.vue';
import MissingAuthorisationError from '@/views/MissingAuthorisationError.vue';

import MarketPlaceRoute from '@/modules/appMarketplace/routes';
import { RouteLocationNormalized } from 'vue-router';

export const ERROR_ROUTES = {
    GENERIC: 'error',
    UNAUTHORISED: 'not-authorised',
};
const Manticore = () => import('@/components/Manticore/index.vue');

const routes: (BkNextRouteInterface)[] = [
    ...ManticoreRoutes,
    {
        path: '/',
        component: Manticore,
        redirect: '/overview',
        children: [],
        props: {
            iFrameUrl: 'site/index',
        },
        meta: {
            title: 'sidenav.overview.main.label',
        },
    },
    {
        path: '/bookingkit',
        name: SHORTCUTS_ROUTES_NAMES.BOOKINGKIT,
        label: 'sidenav.shortcuts.bookingKit.label',
        component: Manticore,
        props: {
            iFrameUrl: 'site/index',
        },
        meta: {
            title: 'sidenav.shortcuts.bookingKit.label',
        },
    },
    {
        path: '/bookingkit-reach',
        name: SHORTCUTS_ROUTES_NAMES.REACH,
        label: 'sidenav.shortcuts.bookingKitReach.label',
        component: Manticore,
        props: {
            iFrameUrl: 'reach/index',
        },
        meta: {
            title: 'sidenav.shortcuts.bookingKitReach.label',
        },
    },
    {
        path: '/add/booking',
        name: SHORTCUTS_ROUTES_NAMES.BOOKING,
        label: 'sidenav.shortcuts.booking.label',
        component: Manticore,
        props: (route:RouteLocationNormalized) => ({
            iFrameUrl: 'order/new',
            query: route?.query,
        }),
        meta: {
            title: 'sidenav.shortcuts.booking.label',
            scopesRequired: [SCOPE_ORDER_CREATE_AND_EDIT, SCOPE_BOOKING_WRITE],
            sidenav: {
                position: SIDEBAR_POSITIONS.QUICK_ACTIONS_SHORTCUTS,
            },
            manticoreMatches: [/order\/new+(([?/])+.*)?/],
        },
    },
    {
        path: '/add/date',
        name: SHORTCUTS_ROUTES_NAMES.DATE,
        component: Manticore,
        label: 'sidenav.shortcuts.appointment.label',
        meta: {
            title: 'sidenav.shortcuts.experience.label',
            scopesRequired: [],
            sidenav: {
                position: SIDEBAR_POSITIONS.QUICK_ACTIONS_SHORTCUTS,
            },
        },
    },
    {
        path: '/add/experience',
        name: SHORTCUTS_ROUTES_NAMES.EXPERIENCE,
        component: Manticore,
        label: 'sidenav.shortcuts.experience.label',
        props: {
            iFrameUrl: 'event/create',
        },
        meta: {
            title: 'sidenav.shortcuts.experience.label',
            scopesRequired: [SCOPE_EVENT, SCOPE_EVENT_WRITE],
            sidenav: {
                position: SIDEBAR_POSITIONS.QUICK_ACTIONS_SHORTCUTS,
            },
            manticoreMatches: [/event\/create([?/])+.*/, /event\/create+.*/, /event\/documents([?/])+.*/],
        },
    },
    {
        path: '/help/knowledge-base',
        name: SHORTCUTS_ROUTES_NAMES.KNOWLEDGEBASE,
        label: 'sidenav.shortcuts.knowledgeBase.label',
        meta: {
            title: 'sidenav.shortcuts.knowledgeBase.label',
        },
    },
    {
        path: '/help/onboarding-wizard',
        name: SHORTCUTS_ROUTES_NAMES.CONTACTSUPPORT,
        label: 'sidenav.shortcuts.contactSupport.label',
        meta: {
            title: 'sidenav.shortcuts.contactSupport.label',
        },
    },
    { ...AdminRoutes } as BkNextRouteInterface,
    { ...Overview } as BkNextRouteInterface,
    { ...CalendarRoutes } as BkNextRouteInterface,
    { ...OrdersRoutes } as BkNextRouteInterface,
    { ...InventoryRoutes } as BkNextRouteInterface,
    { ...ReportsRoutes } as BkNextRouteInterface,
    { ...WalletManagement } as BkNextRouteInterface,
    { ...MarketingRoutes } as BkNextRouteInterface,
    { ...ReachRoutes } as BkNextRouteInterface,
    { ...MarketPlaceRoute } as BkNextRouteInterface,
    { ...ProductUpdatesRoutes } as BkNextRouteInterface,
    { ...SettingsRoutes } as BkNextRouteInterface,
    // Removed until integrating CORE2-428
    // { ...AdminRoutes } as BkNextRouteInterface,
    { ...UserRoutes } as BkNextRouteInterface,
    {
        path: `/${OVERVIEW_ROUTES_NAMES.MAIN}`,
        name: OVERVIEW_ROUTES_NAMES.MAIN,
        label: 'sidenav.overview.main.label',
        component: Manticore,
        meta: {
            title: 'sidenav.overview.main.label',
            manticoreMatches: [/site\/index+\?+.*/, /site\/index/],
        },
        props: {
            iFrameUrl: 'site/index',
        },
    },
    {
        path: '/not-authorised',
        name: ERROR_ROUTES.UNAUTHORISED,
        component: MissingAuthorisationError,
    },
    {
        path: '/error',
        name: ERROR_ROUTES.GENERIC,
        component: Error,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { path: '/overview' },
        meta: {
            title: 'sidenav.overview.main.label',
        },
    },
];

export default routes;
