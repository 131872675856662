import { getEnrichedRoute } from '@/router/helpers';
import { BkNextMainModuleRouteInterface, SIDEBAR_POSITIONS } from '@/router/helpers/constants';
import useIdentifyService from '@/composables/useElementIdentifierService';
import { ORDER_ROUTES_NAMES } from '@/constants/routeNames';
import { RouteLocationNormalized } from 'vue-router';
import { format, subWeeks } from 'date-fns';
import { ManticoreComponentProps } from '@/components/Manticore/useManticoreNavigation';
import { ORDERS_MODULE } from '@/constants/modules';
import { getModuleMessages } from './locales';
import Home from './index.vue';

const Manticore = () => import('@/components/Manticore/index.vue');
const { identify } = useIdentifyService({ prefix: 'navBlock.orders' });
const routes = {
    path: `/${ORDER_ROUTES_NAMES.MAIN}`,
    name: ORDER_ROUTES_NAMES.MAIN,
    label: 'sidenav.orders.main.label',
    component: Home,
    hiddenRoutes: [ORDER_ROUTES_NAMES.FLEXIBLE_TICKETS_DETAILS, ORDER_ROUTES_NAMES.ORDER_DETAILS, ORDER_ROUTES_NAMES.REQUESTS_DETAILS,
        ORDER_ROUTES_NAMES.VOUCHERS_DETAILS, ORDER_ROUTES_NAMES.COUPONS_DETAILS],
    meta: {
        title: 'sidenav.orders.orders.label',
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.TOP,
            icon: 'dollarSign',
        },
        moduleName: ORDERS_MODULE,
        loadModuleAssets: getModuleMessages,
    },
    children: [
        {
            path: `/${ORDER_ROUTES_NAMES.ORDERS}/:code?`,
            name: ORDER_ROUTES_NAMES.ORDERS,
            label: 'sidenav.orders.orders.label',
            component: Manticore,
            meta: {
                title: 'sidenav.orders.orders.label',
                manticoreMatches: [/order\/index\/+(?<code>[^?]+)?/, /order\/index+([?/])?/],
                iFrameUrl: 'order/index',
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: (route?.params?.code) ? `order/index/${route.params.code}` : 'order/index',
            }),
            'data-cy': identify('orders'),
        },
        {
            path: `/${ORDER_ROUTES_NAMES.REQUESTS}/:code?`,
            name: ORDER_ROUTES_NAMES.REQUESTS,
            label: 'sidenav.orders.requests.label',
            component: Manticore,

            meta: {
                title: 'sidenav.orders.requests.label',
                manticoreMatches: [/order\/request\/+(?<code>[^?]+)?/, /order\/request+([?/])?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: (route?.params?.code) ? `order/request/${route.params.code}` : 'order/request',
            }),
            'data-cy': identify('requests'),
        },
        {
            path: `/${ORDER_ROUTES_NAMES.VOUCHERS}`,
            name: ORDER_ROUTES_NAMES.VOUCHERS,
            label: 'sidenav.orders.vouchers.label',
            component: Manticore,
            meta: {
                title: 'sidenav.orders.vouchers.label',
                manticoreMatches: [/voucher\/index+([?/])?/, /voucher+([?/])?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: 'voucher/index',
            }),
            'data-cy': identify('vouchers'),
        },
        {
            path: `/${ORDER_ROUTES_NAMES.VOUCHERS}/:code`,
            name: ORDER_ROUTES_NAMES.VOUCHERS_DETAILS,
            label: 'sidenav.orders.vouchers.label',
            component: Manticore,
            meta: {
                title: 'sidenav.orders.vouchers.label',
                manticoreMatches: [/voucher\/voucherDetail\?code=+(?<code>[^?]+)?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: 'voucher/voucherDetail',
                query: {
                    code: route.params.code,
                },
            }),
        },
        {
            path: ORDER_ROUTES_NAMES.PARTICIPANTS,
            name: ORDER_ROUTES_NAMES.PARTICIPANTS,
            label: 'sidenav.orders.participants.label',
            component: Manticore,
            meta: {
                title: 'sidenav.orders.participants.label',
                manticoreMatches: [/order\/participants+(([?/])+.*)?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => {
                const now = new Date();
                const nextWeek = subWeeks(now, 1);

                return {
                    iFrameUrl: 'order/participants',
                    query: {
                        service_date_from: format(nextWeek, 'yyyy-MM-d'),
                        service_date_to: format(now, 'yyyy-MM-d'),
                        service_date: `${format(nextWeek, 'yyyy.MM.d')} - ${format(now, 'yyyy.MM.d')}`,
                    },
                };
            },
            'data-cy': identify('participants'),
        },
        {
            path: `/${ORDER_ROUTES_NAMES.COUPONS}`,
            name: ORDER_ROUTES_NAMES.COUPONS,
            label: 'sidenav.orders.promotionalCoupons.label',
            component: Manticore,
            meta: {
                title: 'sidenav.orders.promotionalCoupons.label',
                manticoreMatches: [/coupon\/index+([?/])?/, /coupon+([?/])?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: 'coupon/index',
            }),
            'data-cy': identify('coupons'),
        },
        {
            path: `/${ORDER_ROUTES_NAMES.COUPONS}/:code`,
            name: ORDER_ROUTES_NAMES.COUPONS_DETAILS,
            label: 'sidenav.orders.promotionalCoupons.label',
            component: Manticore,
            meta: {
                title: 'sidenav.orders.promotionalCoupons.label',
                manticoreMatches: [/coupon\/index\?code=+(?<code>[^?]+)?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: 'coupon/index',
                query: {
                    code: route.params.code,
                },
            }),
        },
        {
            path: `/${ORDER_ROUTES_NAMES.FLEXIBLE_TICKETS}`,
            name: ORDER_ROUTES_NAMES.FLEXIBLE_TICKETS,
            label: 'sidenav.orders.flexibleTickets.label',
            component: Manticore,
            meta: {
                title: 'sidenav.orders.flexibleTickets.label',
                manticoreMatches: [/flexTicket\/index+([?/])?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: 'flexTicket/index',
            }),
            'data-cy': identify('flexibleTickets'),
        },
        {
            path: `/${ORDER_ROUTES_NAMES.FLEXIBLE_TICKETS}/:code?`,
            name: ORDER_ROUTES_NAMES.FLEXIBLE_TICKETS_DETAILS,
            label: 'sidenav.orders.flexibleTickets.label',
            component: Manticore,
            meta: {
                title: 'sidenav.orders.flexibleTickets.label',
                manticoreMatches: [/flexTicket\/ticketDetail\?code=+(?<code>[^?]+)?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: 'flexTicket/ticketDetail',
                query: {
                    code: route.params.code,
                },
            }),
        },
    ],
} as BkNextMainModuleRouteInterface;

export default getEnrichedRoute(routes, ORDERS_MODULE, getModuleMessages);
