import {VendorAccessScopesEnum} from "@bookingkit-private/api-v4/models/vendor-access";

export class SupplierServiceError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {

            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SupplierServiceError);
        }

        this.name = 'SupplierServiceError';
    }

    static fromMalformedResponse(missingParam: string): SupplierServiceError {
        return new SupplierServiceError(`Response was missing key parameters. Looking for ${missingParam} but never found it`);
    }

    static fromMissingProperty(property:string): SupplierServiceError {
        return new SupplierServiceError(`Property ${property} is missing in the data`);
    }

    static fromCurrentSupplier(supplierId:string): SupplierServiceError {
        return new SupplierServiceError(`Supplier with id ${supplierId} is already in use`);
    }

    static fromCurrentSupplierScopes(scope:VendorAccessScopesEnum): SupplierServiceError {
        return new SupplierServiceError(`Invalid scope ${scope}`);
    }
}

export default SupplierServiceError;
