import { RouteLocationNormalized } from 'vue-router';
import {
    getEnrichedRoute,
} from '@/router/helpers';
import { SIDEBAR_POSITIONS, BkNextMainModuleRouteInterface } from '@/router/helpers/constants';
import useIdentifyService from '@/composables/useElementIdentifierService';
import { INVENTORY_ROUTES_NAMES, LEGACY_ROUTE_NAME } from '@/constants/routeNames';
import {
    MODULE_CROSS_SELLING,
    MODULE_FLEX_TICKET, MODULE_FLEXIBLE_PRICING,
    MODULE_PRODUCT_BUNDLES,
    MODULE_RESOURCE_MGMT,
} from '@/models/Modules';
import { ManticoreComponentProps } from '@/components/Manticore/useManticoreNavigation';
import { INVENTORY_MODULE } from '@/constants/modules';
import { getModuleMessages } from './locales';
import Home from './index.vue';

const Manticore = () => import('@/components/Manticore/index.vue');

const { identify } = useIdentifyService({ prefix: 'navBlock.inventory' });
const routes = {
    path: `/${INVENTORY_ROUTES_NAMES.MAIN}`,
    name: INVENTORY_ROUTES_NAMES.MAIN,
    label: 'sidenav.inventory.main.label',
    hiddenRoutes: [
        LEGACY_ROUTE_NAME.EVENT_RULE,
        LEGACY_ROUTE_NAME.EVENT_DATE_DUPLICATE,
        LEGACY_ROUTE_NAME.EVENT_PRICING,
        INVENTORY_ROUTES_NAMES.EXPERIENCE_DETAIL,
        INVENTORY_ROUTES_NAMES.EXPERIENCE_PRICING,
        INVENTORY_ROUTES_NAMES.OFFERSDUPLICATE,
    ],
    component: Home,
    meta: {
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.TOP,
            icon: 'box',
        },
        title: 'sidenav.inventory.main.label',
        moduleName: INVENTORY_MODULE,
        loadModuleAssets: getModuleMessages,
        manticoreMatches: [/event\/marketingContent+(([?/])+.*)?/],
    },
    children: [
        {
            path: `/${INVENTORY_ROUTES_NAMES.MAIN}/`,
            name: INVENTORY_ROUTES_NAMES.EXPERIENCES,
            label: 'sidenav.inventory.experiences.label',
            component: Manticore,
            props: (route:RouteLocationNormalized):ManticoreComponentProps => {
                const iFrameUrl = `event/index/${route.params.code}`;
                return {
                    iFrameUrl,
                };
            },
            meta: {
                title: 'sidenav.inventory.experiences.label',
                manticoreMatches: [/event\/index+(([?/])+.*)?/, /eventRule\/updatePricing+(([?/])+.*)?/,
                    /eventRule\/delete+(([?/])+.*)?/, /event\/updatePricing+(([?/])+.*)?/,
                ],
            },
            'data-cy': identify('experiences'),
        },
        {
            path: `/${INVENTORY_ROUTES_NAMES.MAIN}/:id`,
            name: INVENTORY_ROUTES_NAMES.EXPERIENCE_DETAIL,
            label: 'sidenav.inventory.experiences.label',
            component: Manticore,
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: `event/update/${route.params.id}`,
            }),
            meta: {
                title: 'sidenav.inventory.experiences.label',
                manticoreMatches: [/event\/update\/+(?<id>[^?]+)?/],
            },
            'data-cy': identify('experience-details'),
        },
        {
            path: `/${INVENTORY_ROUTES_NAMES.MAIN}/:id/pricing`,
            name: INVENTORY_ROUTES_NAMES.EXPERIENCE_PRICING,
            label: 'sidenav.inventory.experiences.label',
            component: Manticore,
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: `event/pricing/${route.params.id}`,
            }),
            meta: {
                title: 'sidenav.inventory.experiences.label',
                manticoreMatches: [/event\/pricing\/+(?<id>[^?]+)?/],
            },
            'data-cy': identify('experience-pricing'),
        },
        {
            path: INVENTORY_ROUTES_NAMES.OFFERS,
            name: INVENTORY_ROUTES_NAMES.OFFERS,
            label: 'sidenav.inventory.flexibleOffers.label',
            component: Manticore,
            meta: {
                title: 'sidenav.inventory.flexibleOffers.label',
                modulesToBeActive: [MODULE_FLEX_TICKET],
                manticoreMatches: [/flexOffer\/index+(([?/])+.*)?/, /flexOffer\/create+(([?/])+.*)?/, /flexOffer\/update+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'flexOffer/index',
            },
            'data-cy': identify('flexibleOffers'),
        },
        {
            path: `/${INVENTORY_ROUTES_NAMES.OFFERSDUPLICATE}/:code`,
            name: INVENTORY_ROUTES_NAMES.OFFERSDUPLICATE,
            label: 'sidenav.inventory.flexibleOffersDuplicate.label',
            component: Manticore,
            meta: {
                title: 'sidenav.inventory.flexibleOffersDuplicate.label',
                modulesToBeActive: [MODULE_FLEX_TICKET, MODULE_FLEXIBLE_PRICING],
                manticoreMatches: [/flexOffer\/duplicate\/+(?<code>[^?]+)?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: `flexOffer/duplicate/${route.params.id}`,
            }),
            'data-cy': identify('flexibleOffersDuplicate'),
        },
        {
            path: INVENTORY_ROUTES_NAMES.PRODUCTS,
            name: INVENTORY_ROUTES_NAMES.PRODUCTS,
            label: 'sidenav.inventory.products.label',
            component: Manticore,
            meta: {
                title: 'sidenav.inventory.products.label',
                manticoreMatches: [/product\/index+(([?/])+.*)?/],
                modulesToBeActive: [MODULE_CROSS_SELLING],
            },
            props: {
                iFrameUrl: 'product/index',
            },
            'data-cy': identify('products'),
        },
        {
            path: INVENTORY_ROUTES_NAMES.BUNDLES,
            name: INVENTORY_ROUTES_NAMES.BUNDLES,
            label: 'sidenav.inventory.bundles.label',
            component: Manticore,
            meta: {
                title: 'sidenav.inventory.bundles.label',
                manticoreMatches: [/product\/bundles+(([?/])+.*)?/, /product\/bundle+.*/],
                modulesToBeActive: [MODULE_PRODUCT_BUNDLES],
            },
            props: {
                iFrameUrl: 'product/bundles',
            },
            'data-cy': identify('bundles'),
        },
        {
            path: INVENTORY_ROUTES_NAMES.RESOURCES,
            name: INVENTORY_ROUTES_NAMES.RESOURCES,
            label: 'sidenav.inventory.resources.label',
            component: Manticore,
            meta: {
                title: 'sidenav.inventory.resources.label',
                manticoreMatches: [/resource\/index+(([?/])+.*)?/],
                modulesToBeActive: [MODULE_RESOURCE_MGMT],
            },
            props: {
                iFrameUrl: 'resource/index',
            },
            'data-cy': identify('resources'),
        },
    ],
} as BkNextMainModuleRouteInterface;

export default getEnrichedRoute(routes, INVENTORY_MODULE, getModuleMessages);
