import {
    SearchResult,
} from '@/components/SearchModal/SearchResultType';
import { CurrencyType } from '@/constants/currencySymbols';
import { COUPON } from '@/constants';
import { mainInfoHero } from '@/components/SearchModal/searchResultMapper';
import {CouponResponse} from "@bookingkit-private/api-v4";
import type {
    TranslatedCouponDetailResponse
} from "@bookingkit-private/api-v4/models/translated-coupon-detail-response";
import {MoneyResponse} from "@bookingkit-private/api-v4/models/money-response";
import type {
    DiscountPercentageResponse
} from "@bookingkit-private/api-v4/models/discount-percentage-response";

const getName = (couponName: TranslatedCouponDetailResponse []) => {
    return couponName[0]?.text ?? '';
}
const isDiscountPercentageResponse = (value: any): value is DiscountPercentageResponse => {
    return value && typeof value.percentage === 'number';
};


export const fromApiCouponToSearchResultCoupon = (coupon :CouponResponse) : SearchResult => ({
    scope: COUPON,
    name: getName(coupon.customer_coupon_name ?? []),
    code: coupon.redeem_code ?? '',
    id: coupon.id ?? '',
    orderDate: coupon.create_date ?? '',
    status: undefined,
    headBadges: [{ label: COUPON }],
    mainInfoHero: mainInfoHero({
        amount: (coupon.discount?.value as MoneyResponse)?.value ?? 0,
        currency: (coupon.discount?.value as MoneyResponse)?.currency as CurrencyType,
    },
    isDiscountPercentageResponse(coupon.discount?.value)
        ? { percentage: coupon.discount.value.percentage ?? 0 }
        : undefined)
});

export default { fromApiCouponToSearchResultCoupon };
