// Those routes are not displayed and are referenced only for MantiCore reasons
import {
    ADMIN_ROUTES_NAMES,
    INVENTORY_ROUTES_NAMES,
    LEGACY_ROUTE_NAME,
    MARKETPLACE_ROUTES_NAMES,
    WALLET_ROUTES_NAMES,
} from '@/constants';
import { RouteLocationNormalized } from 'vue-router';
import { ManticoreComponentProps } from '@/components/Manticore/useManticoreNavigation';
import { BOOKINGKIT_ROLES } from '@/constants/roles';

const Manticore = () => import('@/components/Manticore/index.vue');
const SplashLoader = () => import('@/components/Loaders/SplashLoader.vue');

export const ManticoreRoutes = [

    {
        path: `/${WALLET_ROUTES_NAMES.MANGOPAY_WALLET}`,
        name: WALLET_ROUTES_NAMES.MANGOPAY_WALLET,
        component: Manticore,
        meta: {
            manticoreMatches: [/accounting\/mangoPayWallet+(([?/])+.*)?/],
        },
        props: {
            iFrameUrl: 'accounting/mangoPayWallet',
        },
    },
    {
        path: '/calendar/appointment/:code/',
        name: LEGACY_ROUTE_NAME.EVENT_DATE,
        component: Manticore,
        meta: {
            manticoreMatches: [/eventDate\/view\/+(?<code>[^?]+)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: `eventDate/view/${route.params.code}`,
        }),
    },
    {
        path: '/event-date-create/:experienceId?',
        name: LEGACY_ROUTE_NAME.EVENT_RULE_CREATE,
        component: Manticore,
        meta: {
            manticoreMatches: [/eventRule\/update\?event_id=\/+(?<experienceId>[^?]+)?/, /eventRule\/update\?+(([?/])+.*)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: route.params?.experienceId ? `eventRule/update?event_id=${route.params?.experienceId}` : 'eventRule/update',
        }),
    },
    {
        path: '/event-rule/:code?',
        name: LEGACY_ROUTE_NAME.EVENT_RULE,
        component: Manticore,
        meta: {
            manticoreMatches: [/eventRule\/update\/+(?<code>[^?]+)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: `eventRule/update/${route.params.code}`,
        }),
    },
    {
        path: '/eventDate/duplicate',
        name: LEGACY_ROUTE_NAME.EVENT_DATE_DUPLICATE,
        component: Manticore,
        meta: {
            manticoreMatches: [/eventDate\/duplicate+(([?/])+.*)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: 'eventDate/duplicate',
        }),

    },
    {
        path: '/event/duplicate/:code',
        name: LEGACY_ROUTE_NAME.EVENT_DUPLICATE_DETAILS,
        component: Manticore,
        meta: {
            manticoreMatches: [/event\/duplicate\/+(?<code>[^?]+)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: `event/duplicate/${route.params.code}`,
        }),
    },
    {
        path: '/app/:appId',
        name: MARKETPLACE_ROUTES_NAMES.APP_PLACEHOLDER,
        label: 'sidenav.marketplace.main.label',
        component: SplashLoader,
    },
    {
        path: `/${MARKETPLACE_ROUTES_NAMES.MAIN}/:appName`,
        name: MARKETPLACE_ROUTES_NAMES.APP_DETAIL,
        label: 'sidenav.marketplace.main.label',
        component: SplashLoader,
    },
    {
        path: `/${INVENTORY_ROUTES_NAMES.MAIN}/:code?/pricing`,
        name: LEGACY_ROUTE_NAME.EVENT_PRICING,
        component: Manticore,
        meta: {
            manticoreMatches: [/event\/pricing+(([?/])+.*)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: `event/pricing/${route.params.code}`,
        }),

    },
    {
        path: LEGACY_ROUTE_NAME.REDEEM_VOUCHER,
        name: LEGACY_ROUTE_NAME.REDEEM_VOUCHER,
        component: Manticore,
        meta: {
            manticoreMatches: [/order\/redeemVoucher+(([?/])+.*)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: '/order/redeemVoucher',
        }),

    },
    {
        path: '/event/subscribe/:code',
        name: LEGACY_ROUTE_NAME.EVENT_SUBSCRIBE,
        component: Manticore,
        meta: {
            manticoreMatches: [/event\/subscribe\/+(?<code>[^?]+)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: `event/subscribe/${route.params.code}`,
        }),
    },
    {
        path: LEGACY_ROUTE_NAME.EDITAPIVENDOR,
        name: LEGACY_ROUTE_NAME.EDITAPIVENDOR,
        label: 'sidenav.admin.editApiVendor.label',
        component: Manticore,
        meta: {
            title: 'sidenav.admin.editApiVendor.label',
            manticoreMatches: [/admin\/editApiVendor+(([?/])+.*)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: '/admin/editApiVendor',
            query: { vendor_id: route.query?.vendor_id, clientId: route.query?.clientId },
        }),
    },
    {
        path: '/admin-addRate/:code',
        name: LEGACY_ROUTE_NAME.ADMIN_ADD_RATE,
        component: Manticore,
        meta: {
            manticoreMatches: [/admin\/addRate\/+(?<code>[^?]+)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: `admin/addRate/${route.params.code}`,
        }),
    },
    {
        path: '/setupFee/update/:code',
        name: LEGACY_ROUTE_NAME.ADMIN_ADD_RATE,
        component: Manticore,
        meta: {
            manticoreMatches: [/setupFee\/update\/+(?<code>[^?]+)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: `setupFee/update/${route.params.code}`,
        }),
    },
    {
        path: LEGACY_ROUTE_NAME.CREATE_BK_USER,
        name: LEGACY_ROUTE_NAME.CREATE_BK_USER,
        component: Manticore,
        meta: {
            manticoreMatches: [/admin\/createBkUser+(([?/])+.*)?/],
        },
        props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
            iFrameUrl: '/admin/createBkUser',
        }),

    },
];
export default { ManticoreRoutes };
