import {
    getEnrichedRoute,
} from '@/router/helpers';
import { SIDEBAR_POSITIONS, BkNextMainModuleRouteInterface } from '@/router/helpers/constants';
import useIdentifyService from '@/composables/useElementIdentifierService';
import { REACH_ROUTE_NAMES } from '@/constants/routeNames';
import { REACH_BASE_URL } from '@/bootstrap/environment';
import { MODULE_PROMOTE_LOCAL } from '@/models/Modules';
import { REACH_MODULE } from '@/constants/modules';
import { getModuleMessages } from './locales/index';

import Home from './index.vue';

const { identify } = useIdentifyService({ prefix: 'navBlock.reach' });
const Manticore = () => import('@/components/Manticore/index.vue');

export const routes = {
    path: `/${REACH_ROUTE_NAMES.MAIN}`,
    name: REACH_ROUTE_NAMES.MAIN,
    label: 'sidenav.reach.main.label',
    component: Home,
    meta: {
        title: 'sidenav.reach.main.label',
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.TOP,
            icon: 'Rocket',
        },
        moduleName: REACH_MODULE,
        loadModuleAssets: getModuleMessages,
    },
    children: [
        {
            path: new URL(REACH_ROUTE_NAMES.MARKETPLACE, REACH_BASE_URL).toString(),
            name: REACH_ROUTE_NAMES.MARKETPLACE,
            openInNewTab: true,
            label: 'sidenav.reach.marketplace.label',
            'data-cy': identify('marketplace'),
        },
        {
            path: new URL(REACH_ROUTE_NAMES.MY_PARTNERS, REACH_BASE_URL).toString(),
            name: REACH_ROUTE_NAMES.MY_PARTNERS,
            openInNewTab: true,
            label: 'sidenav.reach.myPartners.label',
            'data-cy': identify('myPartners'),
        },
        {
            path: new URL(REACH_ROUTE_NAMES.PARTNER_RATES, REACH_BASE_URL).toString(),
            name: REACH_ROUTE_NAMES.PARTNER_RATES,
            openInNewTab: true,
            label: 'sidenav.reach.partnerRates.label',
            'data-cy': identify('partnerRates'),
        },
        {
            path: new URL(REACH_ROUTE_NAMES.ORDERS, REACH_BASE_URL).toString(),
            name: REACH_ROUTE_NAMES.ORDERS,
            openInNewTab: true,
            label: 'sidenav.reach.reachOrders.label',
            'data-cy': identify('reachOrders'),
        },
        {
            path: new URL(REACH_ROUTE_NAMES.AFFILIATE_LINKS, REACH_BASE_URL).toString(),
            name: REACH_ROUTE_NAMES.AFFILIATE_LINKS,
            openInNewTab: true,
            label: 'sidenav.reach.affiliateLinks.label',
            'data-cy': identify('affiliateLinks'),
        },
        {
            path: new URL(REACH_ROUTE_NAMES.GOOGLE_THINGS_TO_DO, REACH_BASE_URL).toString(),
            name: REACH_ROUTE_NAMES.GOOGLE_THINGS_TO_DO,
            openInNewTab: true,
            label: 'sidenav.reach.googleThingsToDo.label',
            'data-cy': identify('googleThingsToDo'),
        },
        {
            path: REACH_ROUTE_NAMES.PROMOTE,
            name: REACH_ROUTE_NAMES.PROMOTE,
            label: 'sidenav.reach.promote.label',
            component: Manticore,
            meta: {
                title: 'sidenav.reach.promote.label',
                manticoreMatches: [/marketing\/extendedmarketing+(([?/])+.*)?/],
                modulesToBeActive: [MODULE_PROMOTE_LOCAL],
            },
            props: {
                iFrameUrl: 'marketing/extendedmarketing',
            },
            'data-cy': identify('inviteNew'),
        },
    ],
} as BkNextMainModuleRouteInterface;

export default getEnrichedRoute(routes, REACH_MODULE, getModuleMessages);
