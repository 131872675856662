import { inject, injectable } from 'inversify';

import { BOOKINGKIT_API_FACTORY, SUPPLIER_STATE_SERVICE } from '@/bootstrap/ServiceProviders';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import { UseSupplierInterface } from '@/state/supplier';
import { fromApiVoucherToSearchResultVoucher } from '@/services/VoucherService/Reducers';
import { VoucherServiceError } from '@/services/VoucherService/Errors/VoucherServiceError';
import { SearchResult } from '@/components/SearchModal/SearchResultType';
import { PaginatedAndCancellableRequest } from '@/services/VoucherService/types';

@injectable()
export class VoucherService {
    private connection: ReturnType<BookingKitApiFactoryInterface['getConnection']>;

    private supplierId: string;

    constructor(
    @inject(BOOKINGKIT_API_FACTORY) factory: BookingKitApiFactoryInterface,
    @inject(SUPPLIER_STATE_SERVICE) useSupplierState: () => UseSupplierInterface,
    ) {
        const { supplier } = useSupplierState();
        if (!supplier.value?.id) {
            throw VoucherServiceError.fromMissingSupplier();
        }
        this.supplierId = supplier.value.id;
        this.connection = factory.getConnection();
        this.searchVouchers = this.searchVouchers.bind(this);
    }

    async searchVouchers(searchQuery:string, params?: PaginatedAndCancellableRequest): Promise<SearchResult[]> {
        const { supplierId } = this;
        const parameters = params ?? {};
        const {
            pageSize, nextPageToken, signal,
        } = parameters;
        const { data } = await this.connection.searchVouchers(supplierId, searchQuery,
            pageSize,
          1 as unknown as boolean,
          nextPageToken, undefined, { signal });
        const voucherList = data.data;
        if (voucherList) {
            return voucherList.map(fromApiVoucherToSearchResultVoucher);
        }
        return [];
    }
}

export default { VoucherService };
