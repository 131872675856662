import { inject, injectable } from 'inversify';

import { BOOKINGKIT_API_FACTORY } from '@/bootstrap/ServiceProviders';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import { SupplierServiceInterface } from '@/models/Services/SupplierServiceInterface';
import { SupplierType } from '@/models/User';
import { SupplierAlreadyInUseError } from '@/services/SupplierService/Errors/SupplierAlreadyInUseError';
import { ApiV4ClientError } from '@/Factories/BookingkitApi/Errors/ApiV4ClientError';
import { fromApiSupplierToSupplier, fromUserSupplierToSupplier } from './Reducers';

@injectable()
export class SupplierService implements SupplierServiceInterface {
    private connection: ReturnType<BookingKitApiFactoryInterface['getConnection']>;

    constructor(@inject(BOOKINGKIT_API_FACTORY) factory: BookingKitApiFactoryInterface) {
        this.connection = factory.getConnection();

        this.getCurrentSupplierFromRemote = this.getCurrentSupplierFromRemote.bind(this);
        this.searchSupplier = this.searchSupplier.bind(this);
        this.setSupplierOnRemote = this.setSupplierOnRemote.bind(this);
        this.listSuppliers = this.listSuppliers.bind(this);
    }

    async getCurrentSupplierFromRemote(): Promise<SupplierType> {
        const { data } = await this.connection.getCurrentVendor();
        return fromUserSupplierToSupplier(data);
    }

    async searchSupplier(query: string): Promise<SupplierType[]> {
        const { data } = await this.connection.searchVendors(query);
        if (data.data) {
            return data.data.map(fromApiSupplierToSupplier);
        }
        return [];
    }

    async listSuppliers(pageSize: number): Promise<SupplierType[]> {
        const { data } = await this.connection.listVendors(pageSize);
        if (data.data) {
            return data.data.map(fromApiSupplierToSupplier);
        }
        return [];
    }

    async setSupplierOnRemote(supplier: {id: string} | SupplierType): Promise<void> {
        const { id } = supplier;
        try {
            const { data } = await this.connection.setCurrentUserVendor({id});
            return data;
        } catch (e: any) {
            if (e instanceof ApiV4ClientError) {
                throw SupplierAlreadyInUseError.fromCurrentSupplier(id);
            }
            console.log(e);
            throw e;
        }
    }
}

export default SupplierService;
