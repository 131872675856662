import {
    getEnrichedRoute,
} from '@/router/helpers';
import { SIDEBAR_POSITIONS, BkNextRouteInterface } from '@/router/helpers/constants';
import useIdentifyService from '@/composables/useElementIdentifierService';
import { REPORTS_ROUTES_NAMES } from '@/constants/routeNames';
import { IFRAME_URL } from '@/bootstrap/environment';
import { MODULE_AGGREGATE_STATISTICS } from '@/models/Modules';
import { REPORTS_MODULE } from '@/constants/modules';
import { getModuleMessages } from './locales';
import Home from './index.vue';

const { identify } = useIdentifyService({ prefix: 'navBlock.reports' });
const Manticore = () => import('@/components/Manticore/index.vue');

const routes = {
    path: `/${REPORTS_ROUTES_NAMES.MAIN}`,
    name: REPORTS_ROUTES_NAMES.MAIN,
    label: 'sidenav.reports.main.label',
    component: Home,
    meta: {
        title: 'sidenav.reports.main.label',
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.TOP,
            icon: 'PieChart',
        },
    },
    children: [
        {
            path: REPORTS_ROUTES_NAMES.MONTHLYSTATEMENT,
            name: REPORTS_ROUTES_NAMES.MONTHLYSTATEMENT,
            label: 'sidenav.reports.monthlyStatement.label',
            component: Manticore,
            meta: {
                title: 'sidenav.reports.monthlyStatement.label',
                manticoreMatches: [/accounting\/sales+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'accounting/sales',
            },
            'data-cy': identify('monthlyStatement'),
        },
        {
            path: REPORTS_ROUTES_NAMES.STATEMENTS,
            name: REPORTS_ROUTES_NAMES.STATEMENTS,
            label: 'sidenav.reports.statements.label',
            component: Manticore,
            meta: {
                title: 'sidenav.reports.statements.label',
                manticoreMatches: [/accounting\/billing+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'accounting/billing',
            },
            'data-cy': identify('statements'),
            address: {
                url: `${IFRAME_URL}/accounting/billing`,
            },
        },
        {
            path: REPORTS_ROUTES_NAMES.STATISTICS,
            name: REPORTS_ROUTES_NAMES.STATISTICS,
            label: 'sidenav.reports.statistics.label',
            component: Manticore,
            meta: {
                title: 'sidenav.reports.statistics.label',
                manticoreMatches: [/statistic\/index+(([?/])+.*)?/, /statistic\/downloadExport+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'statistic/index#graph_source',
            },
            'data-cy': identify('statistics'),
        },
        {
            path: REPORTS_ROUTES_NAMES.EXPORTS,
            name: REPORTS_ROUTES_NAMES.EXPORTS,
            label: 'sidenav.reports.exports.label',
            component: Manticore,
            meta: {
                title: 'sidenav.reports.exports.label',
                manticoreMatches: [/statistic\/export+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'statistic/export',
            },
            'data-cy': identify('exports'),
        },
        {
            path: REPORTS_ROUTES_NAMES.AGGREGATESTATISTICS,
            name: REPORTS_ROUTES_NAMES.AGGREGATESTATISTICS,
            label: 'sidenav.reports.aggregateStatistics.label',
            component: Manticore,
            meta: {
                title: 'sidenav.reports.aggregateStatistics.label',
                manticoreMatches: [/multiStatistic\/index+(([?/])+.*)?/],
                modulesToBeActive: [MODULE_AGGREGATE_STATISTICS],
            },
            props: {
                iFrameUrl: 'multiStatistic/index',
            },
            'data-cy': identify('aggregateStatistics'),
        },
    ],
} as unknown as BkNextRouteInterface;

export default getEnrichedRoute(routes, REPORTS_MODULE, getModuleMessages);
