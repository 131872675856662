import { inject, injectable } from 'inversify';

import { BOOKINGKIT_API_FACTORY, SUPPLIER_STATE_SERVICE } from '@/bootstrap/ServiceProviders';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import { CouponServiceError }
    from '@/services/CouponService/Errors/CouponServiceError';
import { UseSupplierInterface } from '@/state/supplier';
import { fromApiCouponToSearchResultCoupon } from '@/services/CouponService/Reducers';
import { SearchResult } from '@/components/SearchModal/SearchResultType';
import { PaginatedAndCancellableRequest } from '@/services/CouponService/types';

@injectable()
export class CouponService {
    private connection: ReturnType<BookingKitApiFactoryInterface['getConnection']>;

    private supplierId: string;

    constructor(
    @inject(BOOKINGKIT_API_FACTORY) factory: BookingKitApiFactoryInterface,
    @inject(SUPPLIER_STATE_SERVICE) useSupplierState: () => UseSupplierInterface,
    ) {
        const { supplier } = useSupplierState();
        if (!supplier.value?.id) {
            throw CouponServiceError.fromMissingSupplier();
        }
        this.supplierId = supplier.value.id;
        this.connection = factory.getConnection();
        this.searchCoupons = this.searchCoupons.bind(this);
    }

    async searchCoupons(query: string, params?: PaginatedAndCancellableRequest)
      : Promise<SearchResult[]> {
        const { supplierId } = this;
        const parameters = params ?? {};
        const {
            pageSize, nextPageToken, signal,
        } = parameters;
        const { data } = await this.connection.searchCoupons(supplierId, query, pageSize, 1 as unknown as boolean, nextPageToken, undefined, { signal });
        const couponList = data.data;

        if (couponList) {
            return couponList.map(fromApiCouponToSearchResultCoupon);
        }
        return [];
    }
}

export default { CouponService };
