import { inject, injectable } from 'inversify';

import { BOOKINGKIT_API_FACTORY } from '@/bootstrap/ServiceProviders';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import { UserServiceInterface } from '@/models/UserServiceInterface';
import { UserType } from '@/models/User';
import { fromApiUserToUserTypeReducer } from './Reducers';

@injectable()
export class UserService implements UserServiceInterface {
    private connection: ReturnType<BookingKitApiFactoryInterface['getConnection']>;

    constructor(@inject(BOOKINGKIT_API_FACTORY) factory: BookingKitApiFactoryInterface) {
        this.connection = factory.getConnection();
    }

    public async getCurrentUserFromRemote(): Promise<UserType> {
        const { data } = await this.connection.getUsersMe();
        return fromApiUserToUserTypeReducer(data);
    }
}
export default UserService;
