import { AxiosError } from 'axios';
import { ApiV4ClientError } from '@/Factories/BookingkitApi/Errors/ApiV4ClientError';
import {ApiV4AxiosError} from "@/Factories/BookingkitApi/Errors/ApiV4AxiosError";

export class ValidationError extends ApiV4ClientError {
    constructor(message: string, code: string, details?: Record<string, any>) {
        super(message, code, details);
        this.message = `${this.message}`;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ValidationError);
        }

        this.name = 'ValidationError';
    }

    static fromAxiosError(originalError:  ApiV4AxiosError) {
        return new ValidationError(`Validation error: ${originalError.response?.data?.error_message}`, originalError.response?.data?.error_code ?? 'undefined', originalError.response?.data?.error_details);
    }
}

export default ValidationError;
