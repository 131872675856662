import { User as ApiUser } from '@bookingkit-private/api-v4';
import { UserServiceError } from '@/services/UserService/Errors';
import { UserType } from '@/models/User';
import { DEFAULT_LOCALE } from '@/bootstrap/environment';
import { ApplicationLocales } from '@/constants/locales';
import { UserLocaleEnum as ApiLocales } from '@bookingkit-private/api-v4/models/user';

const localeArray = JSON.parse(ApiLocales.CsCZdaDKdeDEenGBesESfiFIfrFRitITnlNLnnNOsvSEplPLptPTjaJPzhCNelGr);

const fromApiLocaleToApplicationLocale = (locale: string): ApplicationLocale => {
    const localeMap: Record<string, ApplicationLocale> = {
        "cs-CZ": ApplicationLocales.csCz,
        "da-DK": ApplicationLocales.daDk,
        "de-DE": ApplicationLocales.deDe,
        "en-GB": ApplicationLocales.enXY,
        "es-ES": ApplicationLocales.esEs,
        "fi-FI": ApplicationLocales.fiFi,
        "fr-FR": ApplicationLocales.frFr,
        "it-IT": ApplicationLocales.itIt,
        "nl-NL": ApplicationLocales.nlNl,
        "nn-NO": ApplicationLocales.noNo,
        "sv-SE": ApplicationLocales.svSe
    };

    if (localeArray.includes(locale)) {
        return localeMap[locale] || ApplicationLocales.enXY;
    }
    return ApplicationLocales.enXY;
};

export const fromApiUserToUserTypeReducer = (user: ApiUser): UserType => {
    const validators = {
        id: (id: unknown) => typeof id === 'string',
        full_name: (name: unknown) => typeof name === 'string',
        email: (email: unknown) => typeof email === 'string',
        locale: (locale: unknown) => typeof locale === 'string',
    } as Record<keyof ApiUser, (args: unknown) => boolean>;

    Object.keys(validators).forEach((propertyToValidate) => {
        const userProperty = propertyToValidate as unknown as keyof ApiUser;
        if (!validators[userProperty](user[userProperty])) {
            throw UserServiceError.fromMissingProperty(propertyToValidate);
        }
    });

    return {
        id: user.id as UserType['id'],
        name: user.full_name as UserType['name'],
        email: user.email as UserType['email'],
        locale: fromApiLocaleToApplicationLocale(user.locale ?? DEFAULT_LOCALE),
    };
};

export default {
    fromApiUserToUserTypeReducer,
};
