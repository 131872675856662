import { CaptureContext } from '@sentry/types';

import { BindToApplicationConfig, LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';
import { injectable } from 'inversify';

@injectable()
export class ConsoleLoggerService implements LoggerServiceInterface {
    private asConsoleError = true;

    bindToApplication = (options: BindToApplicationConfig, asConsoleError = true): boolean => {
        try {
            this.asConsoleError = asConsoleError;
            // eslint-disable-next-line no-param-reassign
            options.application.config.errorHandler = this.captureException;
            return true;
        } catch (e) {
            return false;
        }
    }

    captureException = (exception: unknown, context?: CaptureContext): string => {
        const array = new Uint32Array(1);
        const errorCode = `BKFE-${(crypto.getRandomValues(array)).toString()}`;
        if (this.asConsoleError) {
            console.error(`🐛 BookingKit Error ${errorCode}🐛`, context, exception);
        } else {
            console.log(`🐛 BookingKit Error ${errorCode}🐛`, context, exception);
        }
        return `BKFE-${errorCode}`;
    }

    // eslint-disable-next-line class-methods-use-this
    setUser(user: {id?: string}):void {
        console.log('Logged user set', user);
    }
}

export default ConsoleLoggerService;
