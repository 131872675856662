import { OrderStatusEnum, VoucherStatusEnum } from '@/constants';
import { CoppiIntents } from '@bookingkit/coppi';
import { Money, PercentageOff } from '@/components/SearchModal/SearchResultType';

export const getStatusIntent = (status:string) => {
    if (status === OrderStatusEnum.Accepted || status === OrderStatusEnum.Reserved || status === VoucherStatusEnum.VALID) {
        return CoppiIntents.success;
    }
    if (status === OrderStatusEnum.Archived || status === OrderStatusEnum.Expired) {
        return CoppiIntents.warning;
    }
    if (status === OrderStatusEnum.Canceled || status === OrderStatusEnum.Declined || status === OrderStatusEnum.Failure || status === VoucherStatusEnum.INVALID) {
        return CoppiIntents.critical;
    }
    return CoppiIntents.info;
};

const paymentStatusIntent = (paymentStatus:string | unknown) => {
    switch (paymentStatus) {
    case 'paid':
        return CoppiIntents.success;
    case 'unpaid':
        return CoppiIntents.critical;
    default:
        return CoppiIntents.info;
    }
};

const formatPercentage = (percentageOff:PercentageOff) => `${percentageOff?.percentage }%`;
export const mainInfoHero = (money?:Money, percentageOff?:PercentageOff, paymentStatus?:string) => {
    type mainInfoType = {
        label:string,
        badge?:{
            type:string,
            label:string,
        }
    };
    const mainInfo = {} as mainInfoType;
    mainInfo.label = percentageOff ? formatPercentage(percentageOff) : `${money?.amount},${money?.currency}`;
    if (paymentStatus) {
        mainInfo.badge = {
            type: paymentStatusIntent(paymentStatus),
            label: paymentStatus,
        };
    }
    return mainInfo;
};
