// Profile
import {
    getEnrichedRoute,
} from '@/router/helpers';
import { BkNextMainModuleRouteInterface, SIDEBAR_POSITIONS } from '@/router/helpers/constants';
import useIdentifyService from '@/composables/useElementIdentifierService';
import { USER_ROUTES_NAMES } from '@/constants/routeNames';
import { MODULE_USER_MANAGEMENT } from '@/models/Modules';
import {
    SCOPE_USERS, SCOPE_USERS_WRITE,
} from '@/models/User';
import { USER_MODULE } from '@/constants/modules';
import { RouteLocationNormalized } from 'vue-router';
import { ManticoreComponentProps } from '@/components/Manticore/useManticoreNavigation';
import Home from './index.vue';

import { getModuleMessages } from './locales';

const { identify } = useIdentifyService({ prefix: 'navBlock.orders' });
const Manticore = () => import('@/components/Manticore/index.vue');

const routes = {
    path: `/${USER_ROUTES_NAMES.MAIN}`,
    name: USER_ROUTES_NAMES.MAIN,
    label: 'sidenav.account.main.label',
    component: Home,
    hiddenRoutes: [USER_ROUTES_NAMES.CONTRACT_CHANGE, USER_ROUTES_NAMES.COMPANY_INVOICE_PAYMENT_DETAILS],
    meta: {
        title: 'sidenav.account.profile.label',
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.ACCOUNT,
        },
        moduleName: USER_MODULE,
        loadModuleAssets: getModuleMessages,
    },
    children: [
        {
            path: USER_ROUTES_NAMES.PROFILE,
            name: USER_ROUTES_NAMES.PROFILE,
            label: 'sidenav.account.profile.label',
            component: Manticore,
            props: {
                iFrameUrl: 'setting/user',
            },
            'data-cy': identify('profile'),
            meta: {
                title: 'sidenav.account.profile.label',
                sidenav: {
                    parent: true,
                    position: SIDEBAR_POSITIONS.ACCOUNT,
                },
                manticoreMatches: [/setting\/user+(([?/])+.*)?/],
            },
        },
        {
            path: USER_ROUTES_NAMES.COMPANY,
            name: USER_ROUTES_NAMES.COMPANY,
            label: 'sidenav.account.companyData.label',
            component: Manticore,
            props: {
                iFrameUrl: 'setting/vendor',
            },
            'data-cy': identify('companyData'),
            meta: {
                title: 'sidenav.account.companyData.label',
                sidenav: {
                    parent: true,
                    position: SIDEBAR_POSITIONS.ACCOUNT,
                },
                manticoreMatches: [/setting\/vendor+(([?/])+.*)?/],
                iFrameUrl: 'setting/vendor',
            },
        },
        {
            path: USER_ROUTES_NAMES.COMPANY_INVOICE_PAYMENT_DETAILS,
            name: USER_ROUTES_NAMES.COMPANY_INVOICE_PAYMENT_DETAILS,
            label: 'sidenav.account.companyData.label',
            component: Manticore,
            props: {
                iFrameUrl: 'setting/vendor#invoice-payment-details',
            },
            'data-cy': identify('companyData'),
            meta: {
                title: 'sidenav.account.companyData.label',
                sidenav: {
                    parent: true,
                    position: SIDEBAR_POSITIONS.ACCOUNT,
                },
                manticoreMatches: [/setting\/vendor+(([?/])+.*)?/],
                iFrameUrl: 'setting/vendor#invoice-payment-details',
            },
        },
        {
            path: USER_ROUTES_NAMES.USERMANAGEMENT,
            name: USER_ROUTES_NAMES.USERMANAGEMENT,
            label: 'sidenav.account.userManagement.label',
            component: Manticore,
            props: {
                iFrameUrl: 'setting/users',
            },
            'data-cy': identify('userManagement'),
            meta: {
                title: 'sidenav.account.userManagement.label',
                sidenav: {
                    parent: true,
                    position: SIDEBAR_POSITIONS.ACCOUNT,
                },
                scopesRequired: [SCOPE_USERS, SCOPE_USERS_WRITE],
                manticoreMatches: [/setting\/users+(([?/])+.*)?/],
                modulesToBeActive: [MODULE_USER_MANAGEMENT],
            },
        },
        {
            path: `/${USER_ROUTES_NAMES.CONTRACT}`,
            name: USER_ROUTES_NAMES.CONTRACT,
            label: 'sidenav.account.contract.label',
            component: Manticore,
            props: {
                iFrameUrl: 'accounting/contract',
            },
            'data-cy': identify('contract'),
            meta: {
                title: 'sidenav.account.contract.label',
                sidenav: {
                    parent: true,
                    position: SIDEBAR_POSITIONS.ACCOUNT,
                },
                manticoreMatches: [/accounting\/contract+([?/])?/],
            },
        },
        {
            path: `/${USER_ROUTES_NAMES.CONTRACT}/:code`,
            name: USER_ROUTES_NAMES.CONTRACT_CHANGE,
            label: 'sidenav.account.contract.label',
            component: Manticore,
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: `accounting/contract/${route.params.code}`,
                query: {
                    v: route.query?.v,
                    s: route.query?.s,
                    tag: route.query?.tag,
                },
            }),
            'data-cy': identify('contract'),
            meta: {
                title: 'sidenav.account.contract.label',
                sidenav: {
                    parent: true,
                    position: SIDEBAR_POSITIONS.ACCOUNT,
                },
                manticoreMatches: [/accounting\/contract\/+(?<code>[^?]+)?/, /accounting\/confirmUpgrade\/+(?<code>[^?]+)?/],
            },
        },
        {
            path: USER_ROUTES_NAMES.SIGNOUT,
            name: USER_ROUTES_NAMES.SIGNOUT,
            label: 'sidenav.account.signOut.label',
            component: Home,
            'data-cy': identify('signOut'),
            meta: {
                title: 'sidenav.account.signOut.label',
                sidenav: {
                    parent: true,
                    position: SIDEBAR_POSITIONS.ACCOUNT,
                },
                logout: true,
            },
        },
    ],
} as BkNextMainModuleRouteInterface;

export default getEnrichedRoute(routes, USER_MODULE, getModuleMessages);
