import posthog, { CaptureOptions, Properties } from 'posthog-js';
import { POST_HOG_API_HOST, POST_HOG_API_KEY } from '@/bootstrap/environment';
import { SupplierType, UserType } from '@/models/User';

export class PostHogService {
    private supplier : SupplierType | undefined;

    private user : UserType | undefined;

    private readonly trackUser: boolean = true;

    constructor(
        trackUser = true,
    ) {
        this.identify = this.identify.bind(this);
        this.trackUser = trackUser;
    }

    public setSupplier(supplier: SupplierType) {
        this.supplier = supplier;
    }

    private isTheUserToBeTracked = ():boolean => this.trackUser;

    public init = (user: UserType):void => {
        this.user = user;
        if (this.isTheUserToBeTracked()) {
            posthog.init(
                POST_HOG_API_KEY,
                {
                    api_host: POST_HOG_API_HOST,
                    autocapture: true,
                    loaded: this.identify,
                    capture_pageleave: false,
                },
            );
        }
    }

    public identify = ():void => {
        if (this.isTheUserToBeTracked() && this.supplier && this.user) {
            posthog.identify(this.supplier?.id, {
                vendor_name: this.supplier?.name,
                service_email: this.user?.email,
            });
        }
    }

    public reset = ():void => {
        if (this.isTheUserToBeTracked()) {
            posthog.reset(true);
        }
    }

    public captureEvent = (event:string, eventProperties? : Properties | null | undefined,
        options?: CaptureOptions | undefined):void => {
        if (this.isTheUserToBeTracked()) {
            posthog.capture(event, eventProperties, options);
        }
    }
}
export default PostHogService;
