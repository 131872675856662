import {
    getEnrichedRoute,
} from '@/router/helpers';
import { SIDEBAR_POSITIONS, BkNextRouteInterface } from '@/router/helpers/constants';
import { ADMIN_ROUTES_NAMES } from '@/constants/routeNames';
import useIdentifyService from '@/composables/useElementIdentifierService';
import { ADMIN_MODULE } from '@/constants/modules';
import { BOOKINGKIT_ROLES } from '@/constants/roles';
import { RouteLocationNormalized } from 'vue-router';
import { ManticoreComponentProps } from '@/components/Manticore/useManticoreNavigation';
import { getModuleMessages } from './locales';

const Home = () => import('./index.vue');

const { identify } = useIdentifyService({ prefix: 'navBlock.calendar' });
const Manticore = () => import('@/components/Manticore/index.vue');

const routes = {
    path: `/${ADMIN_ROUTES_NAMES.MAIN}`,
    name: ADMIN_ROUTES_NAMES.MAIN,
    label: 'sidenav.admin.main.label',
    component: Home,
    hiddenRoutes: [ADMIN_ROUTES_NAMES.PAYMENT_LIST, ADMIN_ROUTES_NAMES.APIVENDORLIST,
        ADMIN_ROUTES_NAMES.EDITAPI, ADMIN_ROUTES_NAMES.EDITAPIDETAILS, ADMIN_ROUTES_NAMES.EDITBKUSER],
    meta: {
        title: 'sidenav.admin.main.label',
        roleRequired: BOOKINGKIT_ROLES.sales,
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.BOTTOM,
            icon: 'key',
        },
    },
    children: [
        {
            path: ADMIN_ROUTES_NAMES.PROVIDER,
            name: ADMIN_ROUTES_NAMES.PROVIDER,
            label: 'sidenav.admin.provider.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.provider.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/vendor+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/vendor',
            },
            'data-cy': identify('modules'),
        },
        {
            path: ADMIN_ROUTES_NAMES.VENDOR_PERFORMANCE,
            name: ADMIN_ROUTES_NAMES.VENDOR_PERFORMANCE,
            label: 'sidenav.admin.vendor_performance.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.vendor_performance.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/vendor_performance+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/assessments',
            },
            'data-cy': identify('vendor_performance'),
        },
        {
            path: ADMIN_ROUTES_NAMES.DASHBOARD,
            name: ADMIN_ROUTES_NAMES.DASHBOARD,
            label: 'sidenav.admin.dashboard.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.dashboard.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/mangoPay+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/mangoPay',
            },
            'data-cy': identify('emailConfiguration'),
        },
        {
            path: ADMIN_ROUTES_NAMES.CLAIMACCOUNT,
            name: ADMIN_ROUTES_NAMES.CLAIMACCOUNT,
            label: 'sidenav.admin.claimAccount.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.claimAccount.label',
                roleRequired: BOOKINGKIT_ROLES.sales,
                manticoreMatches: [/vendor\/claim+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'vendor/claim',
            },
            'data-cy': identify('claimAccount'),
        },
        {
            path: ADMIN_ROUTES_NAMES.OUTSTANDINGPAYMENTS,
            name: ADMIN_ROUTES_NAMES.OUTSTANDINGPAYMENTS,
            label: 'sidenav.admin.outstandingPayments.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.outstandingPayments.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/wirecard+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/wirecard',
            },
            'data-cy': identify('outstandingPayments'),
        },
        {
            path: ADMIN_ROUTES_NAMES.CHARGEVOUCHERS,
            name: ADMIN_ROUTES_NAMES.CHARGEVOUCHERS,
            label: 'sidenav.admin.chargeVouchers.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.chargeVouchers.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/debitVoucher+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/debitVoucher',
            },
            'data-cy': identify('chargeVouchers'),
        },
        {
            path: ADMIN_ROUTES_NAMES.APIPAYMENTS,
            name: ADMIN_ROUTES_NAMES.APIPAYMENTS,
            label: 'sidenav.admin.apiPayments.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.apiPayments.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/debitOrder+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/debitOrder',
            },
            'data-cy': identify('apiPayments'),
        },
        {
            path: ADMIN_ROUTES_NAMES.INVOICES,
            name: ADMIN_ROUTES_NAMES.INVOICES,
            label: 'sidenav.admin.invoices.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.invoices.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/invoice\/index+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'invoice/index',
            },
            'data-cy': identify('invoices'),
        }, {
            path: ADMIN_ROUTES_NAMES.MANAGEAPI,
            name: ADMIN_ROUTES_NAMES.MANAGEAPI,
            label: 'sidenav.admin.manageAPI.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.manageAPI.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/manageApis+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/manageApis',
            },
            'data-cy': identify('manageAPI'),
        }, {
            path: ADMIN_ROUTES_NAMES.VOUCHERIMPORT,
            name: ADMIN_ROUTES_NAMES.VOUCHERIMPORT,
            label: 'sidenav.admin.importVouchers.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.importVouchers.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/voucher\/import+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'voucher/import',
            },
            'data-cy': identify('importVouchers'),
        }, {
            path: ADMIN_ROUTES_NAMES.COUPONIMPORT,
            name: ADMIN_ROUTES_NAMES.COUPONIMPORT,
            label: 'sidenav.admin.importCoupons.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.importCoupons.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/coupon\/import+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'coupon/import',
            },
            'data-cy': identify('importCoupons'),
        }, {
            path: ADMIN_ROUTES_NAMES.ORDERIMPORT,
            name: ADMIN_ROUTES_NAMES.ORDERIMPORT,
            label: 'sidenav.admin.importOrders.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.importOrders.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/import+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/import',
            },
            'data-cy': identify('importOrders'),
        }, {
            path: ADMIN_ROUTES_NAMES.DISPUTES,
            name: ADMIN_ROUTES_NAMES.DISPUTES,
            label: 'sidenav.admin.mangopayDisputes.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.mangopayDisputes.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/help\/disputes+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'help/disputes',
            },
            'data-cy': identify('mangopayDisputes'),
        }, {
            path: ADMIN_ROUTES_NAMES.KYCUNVERIFIFIED,
            name: ADMIN_ROUTES_NAMES.KYCUNVERIFIFIED,
            label: 'sidenav.admin.KYCUnverified.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.KYCUnverified.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/help\/KYCUnverifiedVendors+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'help/KYCUnverifiedVendors',
            },
            'data-cy': identify('KYCUnverified'),
        }, {
            path: ADMIN_ROUTES_NAMES.MARKETPARTNERS,
            name: ADMIN_ROUTES_NAMES.MARKETPARTNERS,
            label: 'sidenav.admin.marketingPartners.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.marketingPartners.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/marketingPartners+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/marketingPartners',
            },
            'data-cy': identify('marketingPartners'),
        }, {
            path: ADMIN_ROUTES_NAMES.RESELLERSPAYMENTSTATUS,
            name: ADMIN_ROUTES_NAMES.RESELLERSPAYMENTSTATUS,
            label: 'sidenav.admin.resellerPaymentStatus.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.marketingPartners.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/resellerPaymentStatus+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/resellerPaymentStatus',
            },
            'data-cy': identify('resellerPaymentStatus'),
        }, {
            path: ADMIN_ROUTES_NAMES.CHANNELS,
            name: ADMIN_ROUTES_NAMES.CHANNELS,
            label: 'sidenav.admin.payoutChannels.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.payoutChannels.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/channels+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/channels',
            },
            'data-cy': identify('payoutChannels'),
        }, {
            path: ADMIN_ROUTES_NAMES.PROCESSUNKNOWN,
            name: ADMIN_ROUTES_NAMES.PROCESSUNKNOWN,
            label: 'sidenav.admin.unknownPaymentrequests.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.unknownPaymentrequests.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/processUnknownPaymentRequests+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/processUnknownPaymentRequests',
            },
            'data-cy': identify('unknownPaymentrequests'),
        }, {
            path: ADMIN_ROUTES_NAMES.DISABLEEVENTDATES,
            name: ADMIN_ROUTES_NAMES.DISABLEEVENTDATES,
            label: 'sidenav.admin.disableEventDates.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.disableEventDates.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/disableEventDates+(([?/])+.*)?/, /admin\/DisableEventDates+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/DisableEventDates',
            },
            'data-cy': identify('disableEventDates'),
        }, {
            path: ADMIN_ROUTES_NAMES.APPLICATIONLIMITS,
            name: ADMIN_ROUTES_NAMES.APPLICATIONLIMITS,
            label: 'sidenav.admin.applicationLimits.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.applicationLimits.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/calendarLimitation+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/calendarLimitation',
            },
            'data-cy': identify('applicaitonLimits'),
        }, {
            path: ADMIN_ROUTES_NAMES.PAYMENTREQUESTS,
            name: ADMIN_ROUTES_NAMES.PAYMENTREQUESTS,
            label: 'sidenav.admin.paymentRequests.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.paymentRequests.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/paymentRequests+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/paymentRequests',
            },
            'data-cy': identify('paymentRequests'),
        }, {
            path: ADMIN_ROUTES_NAMES.MANAGEBKUSERS,
            name: ADMIN_ROUTES_NAMES.MANAGEBKUSERS,
            label: 'sidenav.admin.manageBkUsers.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.manageBkUsers.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/manageBkUsers+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/manageBkUsers',
            },
            'data-cy': identify('manageBkUsers'),
        },
        {
            path: ADMIN_ROUTES_NAMES.APPLICATION,
            name: ADMIN_ROUTES_NAMES.APPLICATION,
            label: 'sidenav.admin.application.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.application.label',
                roleRequired: BOOKINGKIT_ROLES.superAdmin,
                manticoreMatches: [/admin\/application+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/application',
            },
            'data-cy': identify('application'),
        },
        {
            path: ADMIN_ROUTES_NAMES.RATES,
            name: ADMIN_ROUTES_NAMES.RATES,
            label: 'sidenav.admin.rates.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.rates.label',
                roleRequired: BOOKINGKIT_ROLES.admin,
                manticoreMatches: [/admin\/rates+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/rates',
            },
            'data-cy': identify('rates'),
        },
        {
            path: ADMIN_ROUTES_NAMES.PAYMENT_LIST,
            name: ADMIN_ROUTES_NAMES.PAYMENT_LIST,
            component: Manticore,
            meta: {
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/paymentLists+(([?/])+.*)?/],
            },
            props: (route:RouteLocationNormalized) => ({
                query: { api_client_id: route.query?.api_client_id },
                iFrameUrl: 'admin/paymentLists',
            }),
            'data-cy': identify('rates'),
        },
        {
            path: ADMIN_ROUTES_NAMES.SETUPFEE,
            name: ADMIN_ROUTES_NAMES.SETUPFEE,
            label: 'sidenav.admin.setupFee.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.setupFee.label',
                roleRequired: BOOKINGKIT_ROLES.admin,
                manticoreMatches: [/setupFee\/index+(([?/])+.*)?/, /setupFee\/create+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'setupFee/index',
            },
            'data-cy': identify('setupFee'),
        },
        {
            path: ADMIN_ROUTES_NAMES.CHANGERATES,
            name: ADMIN_ROUTES_NAMES.CHANGERATES,
            label: 'sidenav.admin.changeRates.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.changeRates.label',
                roleRequired: BOOKINGKIT_ROLES.admin,
                manticoreMatches: [/admin\/changeRates+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/changeRates',
            },
            'data-cy': identify('changeRates'),
        },
        {
            path: ADMIN_ROUTES_NAMES.CREATEACCOUNT,
            name: ADMIN_ROUTES_NAMES.CREATEACCOUNT,
            label: 'sidenav.admin.createAccount.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.createAccount.label',
                roleRequired: BOOKINGKIT_ROLES.sales,
                manticoreMatches: [/vendor\/create+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'vendor/create',
            },
            'data-cy': identify('createAccount'),
        },
        {
            path: `/${ADMIN_ROUTES_NAMES.APIVENDORLIST}/:code`,
            name: ADMIN_ROUTES_NAMES.APIVENDORLIST,
            label: 'sidenav.admin.apiVendorList.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.apiVendorList.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/apiVendorsList\?ident=+(?<code>[^?]+)?/, /admin\/apiVendorsList\/\?ident=+(?<code>[^?]+)?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: `admin/apiVendorsList/${route.params.code}`,
            }),
            'data-cy': identify('apiVendorsList'),
        },
        {
            path: `/${ADMIN_ROUTES_NAMES.EDITAPI}`,
            name: ADMIN_ROUTES_NAMES.EDITAPI,
            label: 'sidenav.admin.editApi.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.editApi.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/editApi+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'admin/editApi/',
            },
            'data-cy': identify('editApi'),
        },
        {
            path: `/${ADMIN_ROUTES_NAMES.EDITAPI}/:code`,
            name: ADMIN_ROUTES_NAMES.EDITAPIDETAILS,
            label: 'sidenav.admin.editApi.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.editApi.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/editApi\?ident=+(?<code>[^?]+)?/, /admin\/editApi\/\?ident=+(?<code>[^?]+)?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: `admin/editApi/${route.params.code}`,
            }),
            'data-cy': identify('editApi-details'),
        },
        {
            path: `/${ADMIN_ROUTES_NAMES.EDITBKUSER}/:code`,
            name: ADMIN_ROUTES_NAMES.EDITBKUSER,
            label: 'sidenav.admin.editBkUser.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.editBkUser.label',
                roleRequired: BOOKINGKIT_ROLES.service,
                manticoreMatches: [/admin\/editBkUser\/+(?<code>[^?]+)?/],
            },
            props: (route:RouteLocationNormalized):ManticoreComponentProps => ({
                iFrameUrl: `admin/editBkUser/${route.params.code}`,
            }),
            'data-cy': identify('editBkUser-details'),
        },
        {
            path: `/${ADMIN_ROUTES_NAMES.PLATFORMS}`,
            name: ADMIN_ROUTES_NAMES.PLATFORMS,
            label: 'sidenav.admin.platforms.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.platforms.label',
                manticoreMatches: [/marketing\/portal+(([?/])+.*)?/],
                roleRequired: BOOKINGKIT_ROLES.service,
            },
            props: {
                iFrameUrl: 'marketing/portal',
            },
            'data-cy': identify('platforms'),
        },
        {
            path: `/${ADMIN_ROUTES_NAMES.INVITE}`,
            name: ADMIN_ROUTES_NAMES.INVITE,
            label: 'sidenav.admin.inviteNew.label',
            component: Manticore,
            meta: {
                title: 'sidenav.admin.inviteNew.label',
                manticoreMatches: [/reach\/index+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'reach/index',
            },
            'data-cy': identify('inviteNew'),
        },
    ],
} as unknown as BkNextRouteInterface;

export default getEnrichedRoute(routes, ADMIN_MODULE, getModuleMessages);
