import {GetCurrentVendorResponse, VendorResponse} from '@bookingkit-private/api-v4';
import {
    SCOPE_ACCOUNTING,
    SCOPE_ACCOUNTING_WRITE,
    SCOPE_BOOKING,
    SCOPE_BOOKING_WRITE,
    SCOPE_CUSTOMIZATION,
    SCOPE_CUSTOMIZATION_WRITE,
    SCOPE_EVENT,
    SCOPE_EVENT_WRITE,
    SCOPE_MARKETING,
    SCOPE_MARKETING_WRITE,
    SCOPE_ORDER_CREATE_AND_EDIT,
    SCOPE_ORDER_EDIT_AFTER_SERVICE,
    SCOPE_ORDER_EDIT_REFUND_AMOUNT,
    SCOPE_REACH,
    SCOPE_REACH_WRITE,
    SCOPE_SELL_CHECKOUT_CONFIGURATION,
    SCOPE_SELL_CHECKOUT_CONFIGURATION_WRITE,
    SCOPE_SELL_MARKETING,
    SCOPE_SELL_MARKETING_WRITE,
    SCOPE_SETTING,
    SCOPE_SETTING_WRITE,
    SCOPE_STATISTIC,
    SCOPE_STATISTIC_WRITE,
    SCOPE_STATISTICS_EXPORT,
    SCOPE_STATISTICS_EXPORT_WRITE,
    SCOPE_USERS,
    SCOPE_USERS_WRITE,
    SupplierScopes,
    SupplierScopeType, SupplierType
} from '@/models/User';
import {SupplierServiceError} from '@/services/SupplierService/Errors';
import {VendorAccessScopesEnum} from "@bookingkit-private/api-v4/models/vendor-access";

const fromApiScopeToAppScope = (scope: VendorAccessScopesEnum): SupplierScopeType => {
    switch (scope) {
    case VendorAccessScopesEnum.Accounting:
        return SupplierScopes[SCOPE_ACCOUNTING] as SupplierScopeType;
    case VendorAccessScopesEnum.AccountingWrite:
        return SupplierScopes[SCOPE_ACCOUNTING_WRITE] as SupplierScopeType;
    case VendorAccessScopesEnum.Booking:
        return SupplierScopes[SCOPE_BOOKING]as SupplierScopeType;
    case VendorAccessScopesEnum.BookingWrite:
        return SupplierScopes[SCOPE_BOOKING_WRITE]as SupplierScopeType;
    case VendorAccessScopesEnum.Customization:
        return SupplierScopes[SCOPE_CUSTOMIZATION]as SupplierScopeType;
    case VendorAccessScopesEnum.CustomizationWrite:
        return SupplierScopes[SCOPE_CUSTOMIZATION_WRITE] as SupplierScopeType;
    case VendorAccessScopesEnum.Event:
        return SupplierScopes[SCOPE_EVENT]as SupplierScopeType;
    case VendorAccessScopesEnum.EventWrite:
        return SupplierScopes[SCOPE_EVENT_WRITE]as SupplierScopeType;
    case VendorAccessScopesEnum.Marketing:
        return SupplierScopes[SCOPE_MARKETING]as SupplierScopeType;
    case VendorAccessScopesEnum.MarketingWrite:
        return SupplierScopes[SCOPE_MARKETING_WRITE]as SupplierScopeType;
    case VendorAccessScopesEnum.OrderCreateAndEdit:
        return SupplierScopes[SCOPE_ORDER_CREATE_AND_EDIT]as SupplierScopeType;
    case VendorAccessScopesEnum.OrderEditAfterService:
        return SupplierScopes[SCOPE_ORDER_EDIT_AFTER_SERVICE]as SupplierScopeType;
    case VendorAccessScopesEnum.OrderEditRefundAmount:
        return SupplierScopes[SCOPE_ORDER_EDIT_REFUND_AMOUNT]as SupplierScopeType;
    case VendorAccessScopesEnum.Reach:
        return SupplierScopes[SCOPE_REACH]as SupplierScopeType;
    case VendorAccessScopesEnum.ReachWrite:
        return SupplierScopes[SCOPE_REACH_WRITE]as SupplierScopeType;
    case VendorAccessScopesEnum.SellCheckoutConfiguration:
        return SupplierScopes[SCOPE_SELL_CHECKOUT_CONFIGURATION]as SupplierScopeType;
    case VendorAccessScopesEnum.SellCheckoutConfigurationWrite:
        return SupplierScopes[SCOPE_SELL_CHECKOUT_CONFIGURATION_WRITE]as SupplierScopeType;
    case VendorAccessScopesEnum.SellMarketing:
        return SupplierScopes[SCOPE_SELL_MARKETING]as SupplierScopeType;
    case VendorAccessScopesEnum.SellMarketingWrite:
        return SupplierScopes[SCOPE_SELL_MARKETING_WRITE]as SupplierScopeType;
    case VendorAccessScopesEnum.Setting:
        return SupplierScopes[SCOPE_SETTING]as SupplierScopeType;
    case VendorAccessScopesEnum.SettingWrite:
        return SupplierScopes[SCOPE_SETTING_WRITE]as SupplierScopeType;
    case VendorAccessScopesEnum.Statistic:
        return SupplierScopes[SCOPE_STATISTIC]as SupplierScopeType;
    case VendorAccessScopesEnum.StatisticsExport:
        return SupplierScopes[SCOPE_STATISTICS_EXPORT]as SupplierScopeType;
    case VendorAccessScopesEnum.StatisticsExportWrite:
        return SupplierScopes[SCOPE_STATISTICS_EXPORT_WRITE]as SupplierScopeType;
    case VendorAccessScopesEnum.StatisticWrite:
        return SupplierScopes[SCOPE_STATISTIC_WRITE]as SupplierScopeType;
    case VendorAccessScopesEnum.Users:
        return SupplierScopes[SCOPE_USERS]as SupplierScopeType;
    case VendorAccessScopesEnum.UsersWrite:
        return SupplierScopes[SCOPE_USERS_WRITE]as SupplierScopeType;
    default:
        throw SupplierServiceError.fromCurrentSupplierScopes(`${scope}`);
    }
};


export const fromUserSupplierToSupplier = (response: GetCurrentVendorResponse): SupplierType => {
    if (!response.vendor_access || !response.vendor_details) {
        throw SupplierServiceError.fromMalformedResponse('user_supplier|supplier_details');
    }
    const { vendor_access, vendor_details } = response;
    if (!vendor_access?.id) {
        throw SupplierServiceError.fromMalformedResponse('Supplier Id');
    }
    if (!vendor_details?.name) {
        throw SupplierServiceError.fromMalformedResponse('Supplier Name');
    }
    if (vendor_details?.avatar_url && typeof vendor_details.avatar_url !== 'string') {
        throw SupplierServiceError.fromMalformedResponse('Supplier avatar must be a string');
    }
    if (vendor_details?.account_number !== undefined && typeof vendor_details.account_number !== 'string') {
        throw SupplierServiceError.fromMalformedResponse('Supplier Account number');
    }
    if (!vendor_access?.scopes || !Array.isArray(vendor_access.scopes)) {
        throw SupplierServiceError.fromMalformedResponse('UserSupplier scopes');
    }

    const supplierScopes: (keyof typeof SupplierScopes)[] = [];
    vendor_access.scopes.forEach((scope) => {
        supplierScopes.push(fromApiScopeToAppScope(scope));
    });

    return {
        id: vendor_access.id,
        name: vendor_details.name,
        logo: vendor_details.avatar_url ?? '',
        scopes: supplierScopes,
        accountNumber: vendor_details?.account_number,
        accountStatus: vendor_details?.account_status?.status,
    };
};

export const fromApiSupplierToSupplier = (apiSupplier: VendorResponse): SupplierType & {logo?:string} => {
    if (!apiSupplier.id) {
        throw SupplierServiceError.fromMalformedResponse('Supplier id');
    }
    if (!apiSupplier?.name) {
        throw SupplierServiceError.fromMalformedResponse('Supplier Name');
    }
    if (apiSupplier?.avatar_url && typeof apiSupplier.avatar_url !== 'string') {
        throw SupplierServiceError.fromMalformedResponse('Supplier avatar must be a string');
    }

    return {
        id: apiSupplier.id,
        accountNumber: apiSupplier.account_number,
        logo: apiSupplier.avatar_url ?? '',
        name: apiSupplier.name,
        scopes: [],
    };
};

export default {
    fromUserSupplierToSupplier,
    fromApiSupplierToSupplier,
};
