import { Transaction, TransactionMetadata as ApiTransactionMetadata } from '@bookingkit-private/api-v4';
import {
    TransactionFlowEnum,
    TransactionMetadata,
    WalletTransaction
} from '@/services/AccountingService/types';
import { AccountingServiceError }
    from '@/services/AccountingService/Errors/AccountingServiceError';

const mapTransactionMetadata = (transactionMetaData?: ApiTransactionMetadata):TransactionMetadata =>
{
    if(!transactionMetaData || typeof  transactionMetaData !== 'object') {
        return {
            relatedOrder: {},
            relatedUser: {},
        };
    }
    const { related_order, related_user } = transactionMetaData;
    return {
        relatedOrder: {
            code: related_order?.code,
        },
        relatedUser: {
            fullName: related_user?.full_name,
            id: related_user?.id,
        },
    };
}

export const mapTransactionToWalletTransaction = (data: Transaction): WalletTransaction => {
    const validators = {
        id: (id: unknown) => typeof id === 'string',
        flow: (flow: unknown) => typeof flow === 'string' && [TransactionFlowEnum.Incoming, TransactionFlowEnum.Outgoing].includes(flow as TransactionFlowEnum),
        transaction_meta_data: (transaction_meta_data: unknown) => typeof transaction_meta_data === 'object',
        transaction_type: () => true,
        amount: () => true,
        creation_date: () => true,
        execution_date: () => true,
    } as Record<keyof Omit<Transaction, 'uri'>, (args?: unknown) => boolean>;

    Object.keys(validators).forEach((propertyToValidate) => {
        const transactionProperty = propertyToValidate as unknown as keyof Omit<Transaction, 'uri'>;
        // THIS IS RUNNING THE VALIDATOR HERE ON TOP
        if (!validators[transactionProperty](data[transactionProperty])) {
            throw AccountingServiceError.fromMissingProperty(propertyToValidate);
        }
    });

    return {
        amount: data.amount as WalletTransaction['amount'],
        type: data.transaction_type as WalletTransaction['type'],
        date: data.execution_date,
        flow: data.flow as WalletTransaction['flow'],
        order: data.transaction_meta_data?.related_order,
        metadata: mapTransactionMetadata(data.transaction_meta_data),
    };
};

export default {
    mapTransactionToWalletTransaction,
};
