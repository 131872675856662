import { ModuleArrayMapper } from '@/services/ModuleService/types';
import {
    ModuleResponse,
    ModuleResponseIdEnum
} from '@bookingkit-private/api-v4/models/module-response';
import {ApiModuleToModule, ModuleType} from '@/models/Modules';

const getModuleAsArray = (id:string | undefined) :string[] => {
    const moduleArray = [];
    if (id) {
        const translated = ApiModuleToModule[id as ModuleResponseIdEnum];
        if(translated){
            moduleArray.push(translated as ModuleType);
        }
    }
    return moduleArray;
};

export const fromApiModulesToArrayModules = (module :ModuleResponse) : ModuleArrayMapper => ({
    id: getModuleAsArray(module.id),
    status: module.status,
    activationDate: module.activationDate,
});

export default { fromApiModulesToArrayModules };
