import {ModuleResponseIdEnum} from "@bookingkit-private/api-v4/models/module-response";

export const MODULE_ADDITIONAL_FIELD = 'additional_field';
export const MODULE_ADVERTISED_PRICE = 'advertised_price';
export const MODULE_CONTENT_LOCAL = 'content_local';
export const MODULE_CONTINGENTS = 'contingents';
export const MODULE_CROSS_SELLING = 'cross_selling';
export const MODULE_DEVELOPER = 'developer';
export const MODULE_DIFF_MKT_CONTENT = 'diff_mkt_content';
export const MODULE_E_INVOICE = 'e-invoice';
export const MODULE_EMAIL = 'email';
export const MODULE_EMAIL_CAMPAIGN = 'email_campaign';
export const MODULE_EVENT_ATTACHMENT = 'event_attachment';
export const MODULE_EXTENDED_PRICE = 'extended_price';
export const MODULE_FLEXIBLE_DURATION = 'flexibleduration';
export const MODULE_FLEXIBLE_PRICING = 'flexible_pricing';
export const MODULE_FLEX_TICKET = 'flex_ticket';
export const MODULE_FREE_PARTICIPATE = 'free_participant';
export const MODULE_INVOICE_CORRECT = 'invoice_correct';
export const MODULE_MIN_PARTICIPANTS = 'min_participants';
export const MODULE_NEWSLETTER_OPT = 'newsletter_opt';
export const MODULE_OPENING_HOURS = 'opening_hours';
export const MODULE_PRODUCT_BUNDLES = 'product_bundles';
export const MODULE_PROMOTE_LOCAL = 'promote_local';
export const MODULE_RESOURCE_MGMT = 'resource_mgmt';
export const MODULE_TICKETS = 'tickets';
export const MODULE_THIRD_PARTY_REVIEW = '3rd_party_review';
export const MODULE_USER_MANAGEMENT = 'user_management';
export const MODULE_SUM_UP = 'SumUp';
export const MODULE_YIELD = 'yield';
export const MODULE_AGGREGATE_STATISTICS = 'aggregate_statistics';

export const MODULE_NEW_WIDGETS = 'new_widgets';

export const MODULES = {
    [MODULE_ADDITIONAL_FIELD]: MODULE_ADDITIONAL_FIELD,
    [MODULE_ADVERTISED_PRICE]: MODULE_ADVERTISED_PRICE,
    [MODULE_CONTENT_LOCAL]: MODULE_CONTENT_LOCAL,
    [MODULE_CONTINGENTS]: MODULE_CONTINGENTS,
    [MODULE_CROSS_SELLING]: MODULE_CROSS_SELLING,
    [MODULE_DEVELOPER]: MODULE_DEVELOPER,
    [MODULE_DIFF_MKT_CONTENT]: MODULE_DIFF_MKT_CONTENT,
    [MODULE_E_INVOICE]: MODULE_E_INVOICE,
    [MODULE_EMAIL]: MODULE_EMAIL,
    [MODULE_EMAIL_CAMPAIGN]: MODULE_EMAIL_CAMPAIGN,
    [MODULE_EVENT_ATTACHMENT]: MODULE_EVENT_ATTACHMENT,
    [MODULE_EXTENDED_PRICE]: MODULE_EXTENDED_PRICE,
    [MODULE_FLEXIBLE_DURATION]: MODULE_FLEXIBLE_DURATION,
    [MODULE_FLEXIBLE_PRICING]: MODULE_FLEXIBLE_PRICING,
    [MODULE_FLEX_TICKET]: MODULE_FLEX_TICKET,
    [MODULE_FREE_PARTICIPATE]: MODULE_FREE_PARTICIPATE,
    [MODULE_INVOICE_CORRECT]: MODULE_INVOICE_CORRECT,
    [MODULE_MIN_PARTICIPANTS]: MODULE_MIN_PARTICIPANTS,
    [MODULE_NEWSLETTER_OPT]: MODULE_NEWSLETTER_OPT,
    [MODULE_OPENING_HOURS]: MODULE_OPENING_HOURS,
    [MODULE_PRODUCT_BUNDLES]: MODULE_PRODUCT_BUNDLES,
    [MODULE_PROMOTE_LOCAL]: MODULE_PROMOTE_LOCAL,
    [MODULE_RESOURCE_MGMT]: MODULE_RESOURCE_MGMT,
    [MODULE_TICKETS]: MODULE_TICKETS,
    [MODULE_THIRD_PARTY_REVIEW]: MODULE_THIRD_PARTY_REVIEW,
    [MODULE_USER_MANAGEMENT]: MODULE_USER_MANAGEMENT,
    [MODULE_SUM_UP]: MODULE_SUM_UP,
    [MODULE_YIELD]: MODULE_YIELD,
    [MODULE_AGGREGATE_STATISTICS]: MODULE_AGGREGATE_STATISTICS,
    [MODULE_NEW_WIDGETS]: MODULE_NEW_WIDGETS,
};

export const ApiModuleToModule = {
    [ModuleResponseIdEnum.AdditionalFieldsModule]: MODULE_ADDITIONAL_FIELD,
    [ModuleResponseIdEnum.AdvertisedPriceModule]: MODULE_ADVERTISED_PRICE,
    [ModuleResponseIdEnum.ContentLocalModule]: MODULE_CONTENT_LOCAL,
    [ModuleResponseIdEnum.ContingentsModule]: MODULE_CONTINGENTS,
    [ModuleResponseIdEnum.CrossSellingModule]: MODULE_CROSS_SELLING,
    [ModuleResponseIdEnum.DeveloperModule]: MODULE_DEVELOPER,
    [ModuleResponseIdEnum.DiffMktContentModule]: MODULE_DIFF_MKT_CONTENT,
    [ModuleResponseIdEnum.EInvoiceModule]: MODULE_E_INVOICE,
    [ModuleResponseIdEnum.EmailModule]: MODULE_EMAIL,
    [ModuleResponseIdEnum.EmailCampaignModule]: MODULE_EMAIL_CAMPAIGN,
    [ModuleResponseIdEnum.EventAttachmentModule]: MODULE_EVENT_ATTACHMENT,
    [ModuleResponseIdEnum.ExtendedPriceModule]: MODULE_EXTENDED_PRICE,
    [ModuleResponseIdEnum.FlexibleDurationModule]: MODULE_FLEXIBLE_DURATION,
    [ModuleResponseIdEnum.FlexiblePricingModule]: MODULE_FLEXIBLE_PRICING,
    [ModuleResponseIdEnum.FlexTicketModule]: MODULE_FLEX_TICKET,
    [ModuleResponseIdEnum.FreeParticipantModule]: MODULE_FREE_PARTICIPATE,
    [ModuleResponseIdEnum.InvoiceCorrectModule]: MODULE_INVOICE_CORRECT,
    [ModuleResponseIdEnum.MinParticipantsModule]: MODULE_MIN_PARTICIPANTS,
    [ModuleResponseIdEnum.NewsletterModule]: MODULE_NEWSLETTER_OPT,
    [ModuleResponseIdEnum.OpeningHoursModule]: MODULE_OPENING_HOURS,
    [ModuleResponseIdEnum.ProductBundlesModule]: MODULE_PRODUCT_BUNDLES,
    [ModuleResponseIdEnum.PromoteLocalModule]: MODULE_PROMOTE_LOCAL,
    [ModuleResponseIdEnum.ResourceMgmtModule]: MODULE_RESOURCE_MGMT,
    [ModuleResponseIdEnum.TicketsModule]: MODULE_TICKETS,
    [ModuleResponseIdEnum.ThirdPartyReviewsModule]: MODULE_THIRD_PARTY_REVIEW,
    [ModuleResponseIdEnum.UserMgmtModule]: MODULE_USER_MANAGEMENT,
    [ModuleResponseIdEnum.SumupModule]: MODULE_SUM_UP,
    [ModuleResponseIdEnum.YieldModule]: MODULE_YIELD,
    [ModuleResponseIdEnum.NewWidgetsModule]: MODULE_NEW_WIDGETS,
} as const;


export type ModuleType = keyof typeof MODULES;
