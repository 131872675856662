import { IFRAME_URL, IS_MICRO_FRONTEND } from '@/bootstrap/environment';
import { BkNextRouteInterface } from '@/router/helpers/constants';
import { watch, ref, Ref } from 'vue';
import {
    LocationQuery, RouteLocationNormalized, RouteParamsRaw, Router,
} from 'vue-router';
import loggerServiceInterface, {
    LoggerServiceInterface,
} from '@/models/Services/LoggerServiceInterface';
import {
    IRouterService,
    NavigationRequest,
    NavigationRequestToExternalUrl,
    NavigationRequestToNamedRouteType,
} from '@/router/IRouterService';
import { UnknownManticoreRouteException } from '@/router/Exceptions/UnknownManticoreRouteException';
import { ManticoreRouteNotFoundException } from '@/router/Exceptions/ManticoreRouteNotFoundException';
import {
    LEGACY_ROUTE, MANTICORE_IFRAME_URL,
} from '@/constants/routeNames';

/**
 * Custom routing service for handling navigation at any level in the application (internal, external, iframed).
 * This service is used by all application Components, including microfrontend views
 * @remarks
 * This class is exposed in the {@link NextRoutingPlugin | Next Routing Plugin
 *
 * It exposes two methods to navigate or activate a route
 * and a reactive property containing the current route of the application
 *
 */

export class RouterService implements IRouterService {
    private router: Router;

    private loggerService: loggerServiceInterface;

    public currentRoute: Ref<RouteLocationNormalized>;

    constructor(router: Router, loggerService: LoggerServiceInterface) {
        this.navigateTo = this.navigateTo.bind(this);
        this.resolveManticoreUrlForNamedRoute = this.resolveManticoreUrlForNamedRoute.bind(this);
        this.generateRouteObject = this.generateRouteObject.bind(this);
        this.handleRoutingForSPA = this.handleRoutingForSPA.bind(this);
        this.navigateToNamedRoute = this.navigateToNamedRoute.bind(this);
        this.replaceRoute = this.replaceRoute.bind(this);

        this.isNavigationRequestToExternalUrl = this.isNavigationRequestToExternalUrl.bind(this);
        this.navigateTo = this.navigateTo.bind(this);
        this.router = router;
        this.currentRoute = ref(router.currentRoute.value);
        this.loggerService = loggerService;

        watch(router.currentRoute, () => {
            this.currentRoute.value = router.currentRoute.value;
        });
    }

    private readonly navigateToUrl = (href: string, openInNewTab: boolean | undefined): void => {
        if (openInNewTab) {
            window.open(href, '_blank');
        } else {
            window.location.href = href;
        }
    }

    private resolveRouteFromManticoreUrl = (url: string) => {
        const allRoutes = this.router.getRoutes() as BkNextRouteInterface[];
        const foundRoute = allRoutes.find((route) => {
            if (!route.meta?.manticoreMatches) {
                return false;
            }
            return route.meta.manticoreMatches
                .find((expression) => url.match(expression))
                ?? false;
        });
        if (!(foundRoute && foundRoute.meta)) {
            return null;
        }
        const matchedMatcher = foundRoute?.meta?.manticoreMatches
            ? foundRoute?.meta?.manticoreMatches?.find((exp) => url.match(exp)) : false;

        // Extract eventual parameters from the url
        const urlParams = !matchedMatcher ? {} : matchedMatcher?.exec(
            url,
        )?.groups ?? {};
        const queryString = url.indexOf('?') > -1 ? new URLSearchParams(url.substring(url.indexOf('?') + 1)) : undefined;
        // Extract the query parameters from the url
        // const query = new URLSearchParams(url.split('?')[1]);
        const params = queryString ? Object.fromEntries(queryString) : {};
        const urlparamobj = urlParams ? { ...params, ...urlParams } : params;
        return {
            ...foundRoute,
            params: urlparamobj,
        };
    }

    private readonly getQueryUrn = (query:LocationQuery) => `?${ new URLSearchParams(query as Record<string, string>).toString() }`;

    private readonly resolveManticoreUrlForNamedRoute = (namedRoute: NavigationRequestToNamedRouteType) => {
        const allRoutes = this.router.getRoutes();
        const route: BkNextRouteInterface | undefined = allRoutes
            .find(
                (searchedRoute) => searchedRoute.name === namedRoute.name,
            ) as unknown as BkNextRouteInterface;
        const manticorePath = route?.props?.default?.iFrameUrl ?? false;
        const manticoreFallbackPath = route?.meta?.iFrameUrl ?? false;
        if (manticorePath !== false || manticoreFallbackPath !== false) {
            const urlToNavigateTo: string = manticorePath || manticoreFallbackPath;
            // We should instead use the IFRAME_URL + this path
            return namedRoute.query ? `${IFRAME_URL}/${urlToNavigateTo}${this.getQueryUrn(namedRoute.query)}`
                : `${IFRAME_URL}/${urlToNavigateTo}`;
        }
        if (!route) {
            this.loggerService
                .captureException(ManticoreRouteNotFoundException.fromNamedRoute(namedRoute.name),
                    { extra: { namedRoute } });
        } else {
            this.loggerService
                .captureException(ManticoreRouteNotFoundException.fromNotFoundManticoreUrlInNamedRoute(namedRoute.name),
                    { extra: { route, 'route.props.default': route?.props?.default } });
        }

        return '';
    }

    private readonly generateRouteObject =
        (args: NavigationRequestToNamedRouteType): Partial<NavigationRequestToNamedRouteType> => {
            const {
                name, query, params,
            } = args;
            const routeObject: Partial<NavigationRequestToNamedRouteType> = { name };
            if (query) {
                routeObject.query = query;
            }
            if (params) {
                routeObject.params = params;
            }
            return routeObject;
        }

    private readonly handleRoutingForSPA = (args: NavigationRequestToNamedRouteType): void => {
        const { openInNewTab } = args;

        if (openInNewTab) {
            const route = this.router.resolve(this.generateRouteObject(args));
            this.navigateToUrl(route.href, args.openInNewTab);
            return;
        }

        // Hack here, for two reasons:
        // - The underlying VueRouter will check its own current route and won't trigger
        //   the navigation if it is the same.
        //   Because of manticore internal redirects we are setting the activeRoute
        //   only in this class (to avoid double redirection)
        // - After the user navigated to the next page, by navigating the history back
        //   the record of this navigation will be present
        const routesDiverged = this.currentRoute.value.path !== this.router.currentRoute.value.path;

        if (routesDiverged) {
            this.router.push({ path: this.currentRoute.value.path })
                .then(() => this.router.push({ ...this.generateRouteObject(args) }))
                .catch(this.loggerService.captureException);
        } else {
            this.router.push({ ...this.generateRouteObject(args) })
                .catch(this.loggerService.captureException);
        }
    }

    private navigateToNamedRoute(args: NavigationRequestToNamedRouteType) {
        if (!IS_MICRO_FRONTEND) {
            return this.handleRoutingForSPA(args);
        }

        const href = this.resolveManticoreUrlForNamedRoute(args);

        return this.navigateToUrl(href, args.openInNewTab);
    }

    private readonly isNavigationRequestToExternalUrl =
        (request: NavigationRequest) => (
            request as NavigationRequestToExternalUrl).url !== undefined

    public navigateTo(args: NavigationRequest): void {
        if (this.isNavigationRequestToExternalUrl(args)) {
            const externalUrlArgs = args as NavigationRequestToExternalUrl;
            return this.navigateToUrl(externalUrlArgs.url, externalUrlArgs.openInNewTab);
        }
        return this.navigateToNamedRoute(args as NavigationRequestToNamedRouteType);
    }

    public replaceRoute(route:BkNextRouteInterface | RouteLocationNormalized) : void {
        this.router.replace(route).catch(this.loggerService.captureException);
    }

    public navigateToLegacyRoute = (legacyRoute: string) => {
        const routeToApply = this.resolveRouteFromManticoreUrl(legacyRoute);
        if (!routeToApply) { return; }
        const routeToNavigate = this.router.resolve(routeToApply);

        this.router.push(routeToNavigate).catch((e) => {
            this.loggerService.captureException(e);
        });
    }

    public activateWithoutNavigation = (routePath: string): void => {
        const routeToApply = this.resolveRouteFromManticoreUrl(routePath);
        if (routeToApply && this.currentRoute.value.path === routeToApply.path) {
            return;
        }
        if (!routePath.includes(MANTICORE_IFRAME_URL.ON_BOARDING)) {
            this.currentRoute
                .value = routeToApply
                    ? this.router.resolve(routeToApply) as RouteLocationNormalized
                    : {
                        path: '/legacy',
                        name: LEGACY_ROUTE,
                        routePath,
                    } as unknown as RouteLocationNormalized;
        }
        if (!routeToApply && routePath !== '/' && !routePath.includes(MANTICORE_IFRAME_URL.ON_BOARDING)) {
            this.loggerService
                .captureException(
                    UnknownManticoreRouteException.fromUnfoundedPath(routePath),
                    { extra: { routePath } },
                );
            return;
        }

        const destinationPath = (this.currentRoute.value.path)
            // remove trailing slash
            .replace(/\/\//g, '/');

        // Restricted globals are there for a reason,
        // but the reason does not consider the micro-frontends inside the spa
        // eslint-disable-next-line no-restricted-globals
        history
            .replaceState({}, '',
                // remove double slashes
                destinationPath);
    }
}

export default RouterService;
