import { AxiosError } from 'axios';
import { ApiV4BaseError } from '@/Factories/BookingkitApi/Errors/ApiV4BaseError';
import {ApiV4AxiosError} from "@/Factories/BookingkitApi/Errors/ApiV4AxiosError";

export class ApiV4ClientError extends ApiV4BaseError {
    constructor(message: string, code?: string, details?: Record<string, any>) {
        super(message, code, details);
        this.message = `${this.message}`;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiV4ClientError);
        }

        this.name = 'ApiV4ClientError';
    }

    static fromAxiosError(originalError: ApiV4AxiosError) {
        return new ApiV4ClientError(`Api call failed with status code ${originalError.response?.status}`,
            originalError.response?.data?.error_code,
            originalError.response?.data?.error_details);
    }
}

export default ApiV4ClientError;
