import { getEnrichedRoute } from '@/router/helpers';
import { BkNextRouteInterface, SIDEBAR_POSITIONS } from '@/router/helpers/constants';
import useIdentifyService from '@/composables/useElementIdentifierService';
import { WALLET_ROUTES_NAMES } from '@/constants/routeNames';
import { WALLET_MODULE } from '@/constants/modules';
import { SCOPE_ACCOUNTING, SCOPE_ACCOUNTING_WRITE } from '@/models/User';
import { getModuleMessages } from './locales/index';
import Home from './index.vue';

const { identify } = useIdentifyService({ prefix: 'navBlock.walletManagement' });

const Manticore = () => import('@/components/Manticore/index.vue');
const walletComponent = () => import('./WalletManagement/index.vue');

const routes = {
    path: `/${WALLET_ROUTES_NAMES.WALLET}`,
    name: WALLET_ROUTES_NAMES.MAIN,
    hiddenRoutes: [WALLET_ROUTES_NAMES.PARTNER_PAYMENTS],
    label: 'sidenav.wallet.walletManagement.label',
    component: Home,
    meta: {
        scopesRequired: [SCOPE_ACCOUNTING, SCOPE_ACCOUNTING_WRITE],
        title: 'sidenav.wallet.walletManagement.label',
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.TOP,
            icon: 'Wallet',
        },
    },
    children: [
        {
            path: WALLET_ROUTES_NAMES.WALLET,
            name: WALLET_ROUTES_NAMES.WALLET,
            label: 'sidenav.wallet.walletManagement.label',
            component: walletComponent,
            meta: {
                title: 'sidenav.wallet.walletManagement.label',
            },
            'data-cy': identify('wallet'),
            props: {
                iFrameUrl: 'accounting/index',
                props: WALLET_ROUTES_NAMES.WALLET,
            },
        },
        {
            path: WALLET_ROUTES_NAMES.VERIFICATION,
            name: WALLET_ROUTES_NAMES.VERIFICATION,
            label: 'sidenav.wallet.verification.label',
            component: Manticore,
            meta: {
                title: 'sidenav.wallet.verification.label',
                manticoreMatches: [/setting\/documents+(([?/])+.*)?/, /vendor\/ajaxSaveKyc+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'setting/documents',
            },
            'data-cy': identify('verification'),
        },
        {
            path: WALLET_ROUTES_NAMES.PARTNER_PAYMENTS,
            name: WALLET_ROUTES_NAMES.PARTNER_PAYMENTS,
            label: 'sidenav.wallet.partnerPayments.label',
            component: Manticore,
            meta: {
                title: 'sidenav.wallet.partnerPayments.label',
                manticoreMatches: [/accounting\/partnerPayments+(([?/])+.*)?/],
            },
            props: {
                iFrameUrl: 'accounting/partnerPayments',
            },
            'data-cy': identify('verification'),
        },
    ],
} as unknown as BkNextRouteInterface;

export default getEnrichedRoute(routes, WALLET_MODULE, getModuleMessages);
